import {
    ApolloLink,
    HttpLink,
    InMemoryCache,
} from "@apollo/client";
import { onError } from "apollo-link-error";
import { createUploadLink } from 'apollo-upload-client';

export const httpLink = new createUploadLink({ 
    uri: `${process.env.REACT_APP_API_URL}/graphql?` || 'http://localhost:3001/graphql?',
    cache: new InMemoryCache() 
});

export const setAuthHeaders = new ApolloLink((operation, forward) => {
    operation.setContext({
            headers: {
            authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : null,
            impersonationAuthorization: localStorage.getItem('impersonationToken') ? `Bearer ${localStorage.getItem('impersonationToken')}` : null,
        }
    });
    return forward(operation);
});

export const setContentfulAuthHeaders = new ApolloLink((operation, forward) => {
    operation.setContext({
        headers: {
            authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_TOKEN}`,
        }
    });
    return forward(operation);
});

export const contentfulHttpLink = new HttpLink({ 
    uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/`,
    cache: new InMemoryCache() 
});

export const saveAuthHeaders = new ApolloLink((operation, forward) => {

    return forward(operation).map(response => {
        const context = operation.getContext();
        const authHeader = context.response.headers.get("Authorization");
        if (authHeader) {
            const token = authHeader.replace("Bearer ", "");
            localStorage.setItem('token', token);
        }

        const impersonationHeader = context.response.headers.get("impersonationAuthorization");
        if (impersonationHeader) {
            const token = impersonationHeader.replace("Bearer ", "");
            localStorage.setItem('impersonationToken', token);
        }

        return response;
    });
});

export const authAfterware = ({addError, forward}) => (onError(({ graphQLErrors, networkError }) => {
    if (networkError || graphQLErrors) {
        let graphQLError;
        if (graphQLErrors) {
            try {
                graphQLError = JSON.parse(graphQLErrors[0].message)
            } catch (err) {
                graphQLError = graphQLErrors[0]
            }
        }
        addError(graphQLError || networkError);
    }
}));