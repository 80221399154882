import React, { useEffect, useRef } from 'react'
import { PaddedPage } from 'components/common/Layout';
import ButtonPrimary from 'components/common/Button';
import landing from 'assets/images/landing/landing.jpg';
import trees from 'assets/images/landing/trees.webp';
import { Link } from 'react-router-dom';

export default function Banner() {
  const itemsRef = useRef([]);
  const positionRef = useRef(0);

  useEffect(() => {
    let interval = setInterval(() => {
      // itemsRef.current[positionRef.current % itemsRef.current.length].style.transform = "scale(0.5)";
      // itemsRef.current[positionRef.current % itemsRef.current.length].style.opacity = "50%";
      let newposition = (((Number(itemsRef.current[positionRef.current % itemsRef.current.length].style.left.replace('%', ''))/100 + 2) % 3)-1) *100;
      if (newposition == 0) {
        itemsRef.current[positionRef.current % itemsRef.current.length].style['z-index'] = 10;
        itemsRef.current[positionRef.current % itemsRef.current.length].style.left = newposition + '%';
      } else if (newposition == 100 && itemsRef.current[positionRef.current % itemsRef.current.length].style['z-index'] == 10) {
        itemsRef.current[positionRef.current % itemsRef.current.length].style['z-index'] = 9;
      } else {
        itemsRef.current[positionRef.current % itemsRef.current.length].style.left = newposition + '%';
        itemsRef.current[positionRef.current % itemsRef.current.length].style['z-index'] = 1;
      }
      // itemsRef.current[positionRef.current % itemsRef.current.length].style['z-index'] = "1";
      newposition = (((Number(itemsRef.current[((positionRef.current + 1) % itemsRef.current.length)].style.left.replace('%', ''))/100 + 2) % 3)-1) *100;
      if (newposition == 0) {
        itemsRef.current[((positionRef.current + 1) % itemsRef.current.length)].style['z-index'] = 10;
        itemsRef.current[positionRef.current % itemsRef.current.length].style.left = newposition + '%';
      } else if (newposition == 100 && itemsRef.current[((positionRef.current + 1) % itemsRef.current.length)].style['z-index'] == 10) {
        itemsRef.current[((positionRef.current + 1) % itemsRef.current.length)].style['z-index'] = 9;
      } else {
        itemsRef.current[((positionRef.current + 1) % itemsRef.current.length)].style.left = newposition + '%';
        itemsRef.current[((positionRef.current + 1) % itemsRef.current.length)].style['z-index'] = 1;
      }
      // itemsRef.current[((positionRef.current + 1) % itemsRef.current.length)].style.transform = "scale(1) translate(-50%)";
      // itemsRef.current[((positionRef.current + 1) % itemsRef.current.length)].style.opacity = "100%";
      // itemsRef.current[((positionRef.current + 1) % itemsRef.current.length)].style.left = (((Number(itemsRef.current[((positionRef.current + 1) % itemsRef.current.length)].style.left.replace('%', ''))/100 + 2) % 3)-1) *100 + '%';
      // itemsRef.current[((positionRef.current + 1) % itemsRef.current.length)].style['z-index'] = "10";

      positionRef.current ++;
    }, 3000);

    return () => {
      clearInterval(interval);
    }

  }, [itemsRef]);

  return (
    <div className="relative overflow-hidden">
      <div ref={el => itemsRef.current[0] = el} className="lg:bg-cover bg-top-20 bg-no-repeat bg-auto absolute transition-all duration-1000 w-full" style={{backgroundImage: `url(${landing})`,  left: '0%', zIndex: 10}}>
        <div className="backdrop-brightness-75">
          <PaddedPage className="flex h-80vh" >
              <div className="w-2/3 flex flex-col justify-center items-start gap-8">
                  <h1 className="jumbo text-white backdrop-brightness-90">
                    A farm built for your needs, no matter where you are.
                  </h1>
                  <Link to="/prefab">
                    <ButtonPrimary className="p-12 px-10 font-bold backdrop-brightness-90" white>Explore Our Systems </ButtonPrimary>
                  </Link>
              </div>
          </PaddedPage>
        </div>
      </div>
      <div ref={el => itemsRef.current[1] = el} className="lg:bg-cover bg-no-repeat bg-auto absolute transition-all duration-1000 w-full" style={{backgroundImage: `url(${trees})`, left: '100%'}}>
        <div className="">
          <PaddedPage className="flex h-80vh" >
              <div className="w-2/3 flex flex-col justify-center items-start gap-8">
                  <h1 className="jumbo text-white backdrop-brightness-50">
                    Indigenous owned means strengthening food sovereignty as defined by community
                  </h1>
                  <Link to="/about">
                    <ButtonPrimary className="p-12 px-10 font-bold backdrop-brightness-50" white>Read More</ButtonPrimary>
                  </Link>
              </div>
          </PaddedPage>
        </div>
      </div>
      {/* placeholder */}
      <div className="lg:bg-cover bg-top-20 bg-no-repeat bg-auto invisible" style={{backgroundImage: `url(${landing})`}}>
        <div className="backdrop-brightness-75">
          <PaddedPage className="flex h-80vh" >
              <div className="w-2/3 flex flex-col justify-center items-start gap-8">
                  <h1 className="jumbo text-white backdrop-brightness-90">
                    A farm built for your needs, no matter where you are.
                  </h1>
                  <Link to="/prefab">
                    <ButtonPrimary className="p-12 px-10 font-bold backdrop-brightness-90" white>Explore Our Systems </ButtonPrimary>
                  </Link>
              </div>
          </PaddedPage>
        </div>
      </div>
    </div>
  )
}
