import React, {useContext, useCallback, useEffect, useRef} from 'react';
import { PaddedPage } from 'components/common/Layout';
import AppContext from 'contexts/AppContext';
import ButtonPrimary from 'components/common/Button';
import farm from 'assets/images/prefab/farm.jpg';
import largecontainer from 'assets/images/prefab/12/12trans.png';
import largesolar from 'assets/images/prefab/12/12solartrans.png';
import largegreenhouse from 'assets/images/prefab/12/12GHtrans.png';
import { Link } from 'react-router-dom';

const CustomOptions = () => {
  const {setPage} = useContext(AppContext);
  const canvasRef = useRef();
  const parentRef = useRef();

  useEffect(() => setPage("Prefab Units"), [setPage]);
  
  const drawCanvas = () => {
    let ctx = canvasRef.current.getContext('2d');
    let width = canvasRef.current.width;
    let height = width;
    ctx.beginPath();     // Start a new path.
    ctx.lineWidth = "2";
    ctx.strokeStyle = "white";  // This path is green.
    ctx.arc(width/2, height/2, width * 0.01, 0, 2 * Math.PI);
    ctx.moveTo(width/2, height/2);
    ctx.lineTo(width * 0.6, height * 0.8);
    ctx.lineTo(width * 0.62, height * 0.8);
    ctx.font = "20px Arial";
    ctx.fillStyle = "white";
    ctx.fillText("Hello World", width * 0.62, height * 0.8);
    ctx.stroke();

    ctx.beginPath();
    ctx.arc(width * 0.4, height * 0.5, width * 0.01, 0, 2 * Math.PI);
    ctx.moveTo(width * 0.4, height * 0.5);
    ctx.lineTo(width * 0.2, height * 0.2);
    ctx.lineTo(width * 0.18, height * 0.2);
    ctx.textAlign = "end";
    ctx.fillText("Hello World", width * 0.181, height * 0.2);
    ctx.stroke();  
    ctx.closePath(); // Close the current path.
  }

  const windowResize = useCallback(() => {
      if (canvasRef.current) {
          canvasRef.current.width  = parentRef.current.clientWidth;
          canvasRef.current.height = parentRef.current.clientWidth;
          console.log("what", canvasRef.current);
          drawCanvas();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', windowResize);
    windowResize();
  }, [windowResize]);

  return (
    <div className="bg-cover pt-12"  id="customize">
        <PaddedPage className="flex gap-4 sm:flex-col items-center" >
          <div className="flex flex-col sm:w-full z-10 relative">
              <span className="absolute font-bold text-huge lightgradient -left-16 opacity-50 -top-60 z-0 scale-150">+</span>
              <h1 className="z-10">Tell us what you need</h1>
              <h2 className="z-10">We'll customize</h2>
              <br></br>
              <div className="flex flex-col gap-6 z-10">
                {/* <p>
                  Our container farms come in 3 sizes:
                </p> */}
                <ul className=" rounded-md pl-0 flex list-none flex-col gap-3">
                  <li className="">
                    <h3 className="text-xl">
                       Warranty<span className="lightgradient">+</span><i> included with every system.</i>
                    </h3>
                    <small className="font-light text-base">The first three years are the hardest, and we understand the struggle. That's why we've implemented Warranty+ with all ColdAcre Northern Systems.</small>
                  </li>
                  <li className="">
                    <h3 className="text-xl">
                      Kitchen<span className="lightgradient">+</span>
                    </h3>
                    <small className="font-light text-base">Including a fridge, freezer and/or processing room, this highly-customizable optimization is built to match your community's needs through and through. </small>
                  </li>
                  <li className="">
                    <h3 className="text-xl">
                      Greenhouse<span className="lightgradient">+</span>
                    </h3>
                    <small className="font-light text-base">This system allows your community to grow fruiting crops and root vegetables, with an extended growing season of up to 8-10 months annually.</small>
                  </li>
                  <li className="">
                    <h3 className="text-xl">
                      Solar<span className="lightgradient">+</span>
                    </h3>
                    <small className="font-light text-base">Our roof-mounted Solar+ Optimize Package can support energy usage offset for up to 10-20%, giving you a noticeable reduction in power costs. </small>
                  </li>
                  <li className="">
                    <h3 className="text-xl">
                      Grow<span className="lightgradient">+</span>
                    </h3>
                    <small className="font-light text-base">To support your ongoing needs, ColdAcre offers Grow+, a batch shipping program for hoticubes, fertilizers, system replacement parts, and more at competitive rates.</small>
                  </li>
                </ul>
              </div>
              <br></br>
              <div className="">
                <Link to="/coldacre+" className="self-center">
                  <ButtonPrimary className="mt-2 w-full bg-white text-dark" >Explore Optimize Offerings</ButtonPrimary>
                </Link>
                <Link to="/order" className="self-center">
                  <ButtonPrimary className="mt-2 w-full bg-white text-dark" >Start Customizing Your System Today</ButtonPrimary>
                </Link>
              </div>
          </div>
          <div className="w-2/3 flex flex-col justify-end sm:w-full" ref={parentRef}>
            <div className="w-full h-80 xs:hidden relative">
              <img src={largecontainer} className="absolute bottom-0" alt="large prefab container farm"/>
              <img src={largesolar} className="absolute bottom-0 rotateFarm" alt="large prefab container farm"/>
              <img src={largegreenhouse} className="absolute bottom-0 rotateFarm" alt="large prefab container farm"/>
            </div>
          </div>
        </PaddedPage>
    </div>
  );
};

export default CustomOptions;
