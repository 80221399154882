import React from 'react';
import { ApolloProvider, ApolloClient, from, InMemoryCache } from '@apollo/client';
import { useQuery, gql, useMutation } from "@apollo/client";
import {
  contentfulHttpLink,
  setContentfulAuthHeaders,
} from 'middleware';

const client = new ApolloClient({
  uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/`,
  link: from([
      setContentfulAuthHeaders,
      contentfulHttpLink,
  ]),
  cache: new InMemoryCache()
});

export default function useContentfulRequest({query, variables}) {
  const { loading, error, data } = useQuery(query, {variables, client});
  return {
    loading, 
    error,
    data
  }
}
