import React, {useEffect, useState} from 'react'

export default function SizeSelector({updateSize, sizes}) {
  const [size, setSize] = useState(sizes[0]);
  useEffect(() => {
    updateSize(size.size, true);
  }, []);
  
  const handleSetSize = (size) => {
    setSize(size);
    updateSize(size.size);
  }

  return (
    <div className="flex flex-col gap-4">
      <label>Size</label>
      <div className="flex gap-2">
        {
          sizes.map(currentSize =>   
            <div 
              key={currentSize.size} 
              onClick={() => handleSetSize(currentSize)} 
              className={`${currentSize === size ? 'border-dark-grey' : ''} border rounded px-4 py-2 flex flex-col cursor-pointer grow text-center`}
            >
              <h2 className={`${currentSize === size ? 'text-dark-grey' : 'text-light-grey'}`}>{currentSize.size}m</h2>
              {/* <small className={`${currentSize === size ? 'text-dark-grey' : 'text-light-grey'} font-bold`} >${currentSize.cost}*</small> */}
            </div>
          )
        }
      </div>
      <div className="flex gap-4 justify-center">
          <div className="p2 flex flex-col cursor-pointer text-center">
            <h3 className="">{size.meta.produce}<sub>lbs/week</sub></h3>
            <p className="">{size.meta.lettuce} planting spaces</p>
          </div>
            <div className="p2 flex flex-col cursor-pointer text-center">
            <h3 className="">Feeds {size.meta.feeds} people</h3>
            <p className="">Effective for populations of {size.meta.population} or more.</p>
          </div>
      </div>
      {/* <small className="text-center">
          * Prices shown exclude transport and may vary by territory/province.
      </small> */}
    </div>
  )
}
