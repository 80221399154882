import React from 'react'; 

const HeadingOne = ({ children }) => {
  return (
    <h1 className="
      font-semibold 
      text-h1
			my-0
      "
    >
      {children}
    </h1>
  );
};

export default HeadingOne;
