import React from 'react'; 

const HeadingThree = ({ children }) => {
  return (
    <h3 className="
      font-semibold 
      text-3xl
      my-0
      "
    >
      {children}
    </h3>
  );
};

export default HeadingThree;
