import React, { useState, useEffect, useContext } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeCardSetupForm from './StripeCardSetupForm';
import { useMutation, gql } from "@apollo/client";
import { useHistory } from 'react-router';
import AppContext from 'contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import SuccessNotice from 'components/notices/SuccessNotice';

const INITIALIZE = gql`
  mutation GetPaymentIntent($productId: String!, $reservationId: String!) {
    getPaymentIntent(productId: $productId, reservationId: $reservationId) {
      paymentIntent {
        id,
        amount,
        status,
        client_secret,
        metadata
      },
      paymentMethods
    }
  }
`;

const CONFIRMPAYMENT = gql`
  mutation ConfirmPaymentIntent($paymentIntentId: String!, $paymentMethodId: String) {
    confirmPaymentIntent(paymentIntentId: $paymentIntentId, paymentMethodId: $paymentMethodId) {
      status
    }
  }
`;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function PaymentForm({productId, reservation, onSuccess}) {
  const {setLoading, loading} = useContext(AppContext);
  const [paymentIntent, setPaymentIntent] = useState();
  const [paymentMethods, setPaymentMethods] = useState();
  const navigate = useNavigate();
  const [initializeMutation, { loading:initializeLoading }] = useMutation(INITIALIZE);
  const [confirmPaymentMutation, { loading:confirmPaymentLoading, error:setPaymentError }] = useMutation(CONFIRMPAYMENT);
  
  if (confirmPaymentLoading && !loading) {
    setLoading(true);
  } else if (!confirmPaymentLoading && loading) {
    setLoading(false);
  }
  
  useEffect(() => {
    initializeMutation({ variables: { reservationId: reservation.id, productId} })
      .then(({data}) => {
          setPaymentIntent(data.getPaymentIntent.paymentIntent);
          if (data.getPaymentIntent.paymentMethods && Array.isArray(data.getPaymentIntent.paymentMethods) && data.getPaymentIntent.paymentMethods.length > 0) {
            setPaymentMethods(data.getPaymentIntent.paymentMethods);
          }
      })
      .catch((error) => {
        console.log("error for getting secret", error.message);
        // TODO handle errror
      })
  }, []);
  
  const handleSuccess = (paymentMethodId) => {
    confirmPaymentMutation({ variables: {  paymentIntentId: paymentIntent.id, paymentMethodId } })
      .then(() => {
        // TODO SHOW PAYMENT SUCCESS MESSAGE
        console.log("success referal")
        onSuccess();
      })
      .catch((err) => {
        console.log("payment error", err);
      })
  };
  return (
    <div onClick={(e) => e.stopPropagation()} className="bg-white rounded shadow-md p-4 border-t">
      {paymentIntent ? <Elements stripe={stripePromise} options={{clientSecret: paymentIntent.client_secret}}>
        <StripeCardSetupForm paymentMethods={paymentMethods} setPaymentMethods={setPaymentMethods} paymentIntent={paymentIntent} handleSuccess={handleSuccess}/>
      </Elements>
      :
        <FontAwesomeIcon className="text-xl animate-spin" icon={faSpinner} />
      }
    </div>
  );
}

export default PaymentForm;

