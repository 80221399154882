import React, {useContext, useEffect} from 'react';
import { PaddedPage } from 'components/common/Layout';
import PlantBG from 'assets/images/landing/plant.jpg'
import AppContext from 'contexts/AppContext';
import WinterHarvestJumbo from './WinterHarvestJumbo';
import SubscriptionFAQ from './subscriptions/SubscriptionFAQ';
import holdcontainers from 'assets/images/winterharvest/holdcontainers.jpg';
import innercropbox from 'assets/images/winterharvest/innercropbox.jpg';
import open from 'assets/images/winterharvest/open.jpg';



const WinterHarvestInfo = () => {
  const {setPage} = useContext(AppContext);
  
  return (
    <>
      <div className="flex flex-col gap-12">
        <WinterHarvestJumbo/>
        <PaddedPage className="flex gap-4 sm:z-10 sm:flex-col">
          <div className="w-1/2 relative sm:w-full">
            <div style={{backgroundImage: `url(${open})`}} className="md:absolute bg-cover rounded-xl -top-20 left-1 bg-primary w-80 h-80">
            </div>
            <div style={{backgroundImage: `url(${holdcontainers})`}} className="absolute bg-cover rounded-xl -bottom-20 right-1 bg-secondary w-80 h-80 sm:hidden">
            </div>
          </div>
          <div className="w-1/2 sm:w-full">
            <h1>ColdAcre operates Yukon’s first year-round urban farm.</h1>
            {/* <h2>Sub heading</h2> */}
            <br></br>
            <p>
              We offer fresh greens to our community via our WinterHarvest food subscription, as well as to grocery stores, restaurants, caterers, even our local hospital and correctional centre. Our mission is to get local, pesticide-free greens onto as many plates as possible.
            </p>
            <br></br>
            <p>
              Looking for wholesale greens? Contact <a href="mailto: sales@coldacre.ca">sales@coldacre.ca</a>.
            </p>
          </div>
        </PaddedPage>
        <PaddedPage>
          <SubscriptionFAQ/>
        </PaddedPage>
      </div>
    </>
  );
};

export default WinterHarvestInfo;
