import React, {useState, useEffect} from 'react';
import Deck from './Deck';
import SubscriptionFAQ from '../SubscriptionFAQ';
import { PaddedPage } from 'components/common/Layout';
import ButtonPrimary from 'components/common/Button';
import table from 'assets/images/winterharvest/table.JPG'

const Subscription = () => {
  const [page, setPage] = useState(null);

  return (
    <React.Fragment>
      {page ?
        <>
          <PaddedPage className="flex flex-col items-center">
            <Deck page={page}></Deck>
            <br></br>
            <SubscriptionFAQ page={page}/>
          </PaddedPage>
        </>
        :
        <div className=" absolute bg-cover w-full h-full flex flex-col justify-center items-center gap-6" style={{backgroundImage: `url(${table})`}}>
          <h1 className="text-white backdrop-brightness-75 rounded">Where are you?</h1>
          <div>
            <ButtonPrimary className="bg-white border-white" onClick={() => setPage("whitehorse")}>Whitehorse</ButtonPrimary>
            <ButtonPrimary className="bg-white border-white" onClick={() => setPage("dawson")}>Dawson</ButtonPrimary>
          </div>
        </div>
      }

    </React.Fragment>
  );
}
export default Subscription;