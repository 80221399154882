import React from 'react';
import { gql } from "@apollo/client";
import useContentfulRequest from 'hooks/useContentfulRequest';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import ButtonPrimary from 'components/common/Button';

const GET_SUBSCRIPTION = gql`
  query getSubscription($page: String!) {
    subscriptionCollection(where: {page: $page}) {
      items {
        banner {
          url
        }
        title
        info {
          json
        }
        linkText
        link
        secondInfo {
          json
        }
        secondLinkText
        secondLink
      }
    }
  }
`;


const Deck = (props) => {
  const {data} = useContentfulRequest({query: GET_SUBSCRIPTION, variables: { page: props.page}});
  let subscriptions = data ? [...data.subscriptionCollection.items] : [];
  subscriptions = subscriptions.reverse().map(subscription => {
    return (
      <div className="border rounded border-grey shadow-md my-4 text-center">
        <h2 className="my-4">{subscription.title}</h2>
        <img src={subscription.banner.url} style={{width: '100%', objectFit: "cover"}} alt="Fresh greens"></img>
        <div className="flex flex-col p-4 gap-4 items-center">
          <div  style={{maxWidth: '600px'}} className="mt-3 mb-4 flex flex-col gap-4 text-left">
            {documentToReactComponents(subscription.info.json)}
          </div>
          <a href={subscription.link}><ButtonPrimary>{subscription.linkText}</ButtonPrimary></a>
          <hr className="w-full"></hr>
          {subscription.secondInfo &&
            <div  style={{maxWidth: '600px'}} className="mt-3 mb-4 flex flex-col gap-4 text-left">
              {documentToReactComponents(subscription.secondInfo.json)}
            </div>
          }
          {subscription.secondLink && <a href={subscription.secondLink}><ButtonPrimary>{subscription.secondLinkText}</ButtonPrimary></a>}
        </div>
      </div>
    )
  });
  return (
    <div className="">
      {subscriptions}
    </div>
  );
}

export default Deck;