import React, {useContext, useState} from 'react';
import AppContext from 'contexts/AppContext';
import ButtonPrimary from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Product from './Product';

const Products = ({title}) => {
  const [show, setShow] = useState(true);
    
  return (
      <div className="flex flex-col gap-16">
        <div onClick={() => setShow(!show)} className="w-1/2 cursor-pointer flex justify-between py-2 pr-2 items-center bg-light-light-grey">
            <h2 className>{title}</h2>
            <FontAwesomeIcon className="text-xl" icon={show ? faChevronUp : faChevronDown} />
        </div>
        <div className={`flex flex-col gap-16 ${!show && 'hidden'}`}>
        {[1,2].map(i => <Product key={i}/>)}
        </div>
      </div>
  );
};

export default Products;
