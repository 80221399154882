import React from 'react';
import { Route } from 'react-router-dom';
import SubscriptionOverview from './SubscriptionsOverview';
import Subscriptions from './Subscriptions';


const routes = [
  <Route key="subscriptions" path="/subscriptions" element={<SubscriptionOverview/>} />,
];

export default routes;
