import React from 'react';
import 'styles/App.css';
import AuthFlagsProvider from 'providers/AuthFlagsProvider';
import ApolloClientProvider from 'providers/ApolloClientProvider';
import AppProvider from 'providers/AppProvider';
import NavBar from 'components/NavBar';
import Footer from 'components/footer';
import ScrollToTop from 'components/common/ScrollToTop';
import ScrollChevron from 'components/common/ScrollChevron';
import Notices from 'components/notices';
// routes
import Screens from 'screens';

const App = () => {

  return (
    <AuthFlagsProvider>
      <ApolloClientProvider>
        <AppProvider>
          <div className="min-h-screen flex flex-col justify-between">
            <NavBar />
            <Notices/>
            <ScrollChevron/>
            <Screens/>
            <Footer />
          </div>
          <ScrollToTop/>
        </AppProvider>
      </ApolloClientProvider>
    </AuthFlagsProvider>
  );
};

export default App;
