import React from 'react';
import { Route } from 'react-router-dom';
import Login from './Login';
import Signup from './Signup';
import AnonRoute from 'components/routes/AnonRoute';

const routes = [
  <Route key="login" path="/login" element={<AnonRoute><Login/></AnonRoute>} />,
  <Route key="sign-up" path="/sign-up" exact element={<AnonRoute><Signup/></AnonRoute>} />
];

export default routes;
