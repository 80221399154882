import React, {useState, useContext} from 'react';
import { PaddedPage } from 'components/common/Layout';
import {Input, Label} from 'components/inputs';
import {ButtonPrimary} from 'components/common/Button';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation, gql } from "@apollo/client";
import AppContext from 'contexts/AppContext';

const SIGNUP = gql`
  mutation Signup($email: String!, $firstName: String!, $lastName: String!, $password: String!) {
    signup(email: $email, firstName: $firstName, lastName: $lastName, password: $password) {
      id
    }
  }
`;

const SignUp = () => {
  const {setLoading, loading, setError, error} = useContext(AppContext);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [firstName, setFirstname] = useState();
  const [lastName, setLastname] = useState();
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [signupMutation, { signupLoading, signupError }] = useMutation(SIGNUP);

  if (signupLoading && !loading) {
    setLoading(true);
  } else if (!signupLoading && loading) {
    setLoading(false);
  }

  if (signupError && !error) {
    setError(true);
  } else if (!signupError && error) {
    setError(false);
  }

  const valid = () => {
    return email && firstName && lastName && password && (password === confirmPassword)
  }

  const signUp = () => {
    console.log("signup");
    if (valid()) {
      signupMutation({ variables: { email, firstName, lastName, password } })
        .then(res => {
          console.log("signup2", res);
          // TODO broadcast message of successfull sign up and then 
          navigate('/login');
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  return (
    <PaddedPage>
      <div className="flex flex-col justify-center items-center gap-2">
        <h1>Sign Up</h1>
        <div className="flex flex-col">
          <label>email </label>
          <Input onChange={setEmail}/>
        </div>
        <div className="flex flex-col">
          <label>First Name </label>
          <Input onChange={setFirstname}/>
        </div>
        <div className="flex flex-col">
          <label>Last Name </label>
          <Input onChange={setLastname}/>
        </div>
        <div className="flex flex-col">
          <label>password </label>
          <Input type="password" onChange={setPassword}/>
        </div>
        <div className="flex flex-col">
          <label>confirm password </label>
          <Input type="password" onChange={setConfirmPassword}/>
        </div>
        <p>
          Already have an account? Login <Link to="/login">Here</Link>
        </p>
        <ButtonPrimary disabled={!valid()} onClick={signUp}>Submit</ButtonPrimary>
      </div>
    </PaddedPage>
  );
};

export default SignUp;
