import React, {useRef, useEffect, useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

export default function ScrollChevron() {
  const chevronRef = useRef();
  const [scrollPosition, setScrollPosition] = useState(0);
  
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  const scrollTop = () => {
    document.body.scrollIntoView({behavior: "smooth"});
  }
  return (
<div onClick={scrollTop} className={`fixed cursor-pointer top-16 right-10 ${scrollPosition < 400 && 'hidden'}`}><FontAwesomeIcon className="text-xl" icon={faChevronUp} /></div>
  )
}
