import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import NotFound from './notFound.jsx';

const routes = [
  <Route key="not-found" path="/not-found" element={<NotFound/>}/>,
  <Route key="not-found-redirect" path="/" element={ <Navigate to="/not-found" replace/>} />
];

export default routes;
