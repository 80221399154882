import AppContext from 'contexts/AppContext';
import React, { useContext } from 'react'
import { Link } from 'react-router-dom';

export default function SalesNav() {
  const {page} = useContext(AppContext);
  return (
    <div className="flex gap-4 items-center">
        <Link className={`no-underline font-medium hover:text-dark ${page === "Prefab Units" ? '' : 'text-dark-grey'}`} to="/prefab">Systems</Link>
        <Link className={`no-underline font-medium hover:text-dark ${page === "Order" ? '' : 'text-dark-grey'}`} to="/order">Build Now</Link>

        {/* <Link className="no-underline font-medium" to="/subscriptions">Subscriptions</Link> */}
   </div>
  )
}
