import React, {useContext, useEffect} from 'react';
import { PaddedPage } from 'components/common/Layout';
import AppContext from 'contexts/AppContext';
import about from 'assets/images/landing/about.jpg';
import ButtonPrimary from 'components/common/Button';
import { Link } from 'react-router-dom';
import ourStory from 'assets/images/landing/ourstory.jpg';
import sub from 'assets/images/landing/sub.jpg';
import door from 'assets/images/landing/card1.jpg';
import nnddc from 'assets/images/landing/nnddc.webp';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-regular-svg-icons';
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const AboutPage = () => {
  const {setPage} = useContext(AppContext);
  useEffect(() => setPage("about"), [setPage]);

  return (
    <div className="flex flex-col">
      <div className="bg-cover bg-center bg-no-repeat flex items-center justify-center" style={{backgroundImage: `url(${about})`}}>
        <PaddedPage className="flex h-80vh items-center justify-center" >
          <div className="w-7/8 flex flex-col justify-center items-start gap-8 backdrop-brightness-50 p-8">
              <h1 className="jumbo text-white">
                Our mission is...
              </h1>
              <h2 className="text-white">
                To make a difference in Northern communities by providing access and the means to community grown, reliable, fresh food.
              </h2>
          </div>
        </PaddedPage>
    </div>
      <div className="">
        <PaddedPage className="flex flex-col gap-12">
          <h1 className="jumbo ">Who is ColdAcre?</h1>
          <div className="flex relative z-10 gap-16 sm:flex-col">
            <div className="flex flex-col justify-between w-1/3  sm:w-full">
              <div className="bg-cover self-start bg-no-repeat min-h-80 rounded-2xl bg-bottom -mb-2 w-full" style={{backgroundImage: `url(${ourStory})`}} />
              <div className="bg-cover self-start bg-no-repeat min-h-80 rounded-2xl bg-bottom -mb-2 w-full" style={{backgroundImage: `url(${door})`}} />
              <div className="bg-cover self-start bg-no-repeat min-h-80 rounded-2xl bg-bottom -mb-2 w-full" style={{backgroundImage: `url(${nnddc})`}} />
            </div>
            <div className="w-1/2 sm:w-full">
              <h3 className="text-med-grey text-2xl font-med w-80">Not like the others</h3>
              <p className="mt-4 ">
                It’s unrealistic to expect a custom food-growing system to yield a food solution when its users don’t have the necessary expertise to maximize its potential. 
                <br></br>
                ColdAcre Food Systems is with you every step of the way, providing communities and businesses alike with the necessary hands-on training to ensure you’re able to reliably grow, package, and deliver fresh produce. 
                <br></br>
                We’ll custom build a hydroponic food-growing solution that works for your community, while ensuring you can optimize it to its fullest potential through our training program. Even after the sale, we’re always available, ready to support and help maintain your system in whatever way we can. This gives us the confidence to guarantee food yields.
                <br></br>
              </p>
              <br></br>
              <br></br>
              <h3 className="text-med-grey text-2xl font-med">Supportive Growth Opportunities</h3>
              <p className="mt-4 ">
                ColdAcre has partnered with Indigenous communities in the past, and we’ve had great experiences doing so. We understand what Indigenous communities face daily, which is why we’re not here to sell you the magical answer to all your problems. With respect, we come in with supportive growth opportunities that fit what you deem your community needs.
                <br></br>
                There’s a certain degree of expertise and knowhow that go into our food-growing systems. We know how daunting it can be to not only operate a system like this but also to optimize in full. Yes, our mission is to offer the means to reliable fresh produce, but we also strive to provide the necessary education to use our system, while creating job opportunities within Indigenous communities. 
              </p>
              <br></br>
              <br></br>
              <h3 className="text-med-grey text-2xl font-med">Yukon First Nation Owned</h3>
              <p className="mt-4 ">
                Cold Acre is a subsidiary of Na-Cho Nyäk Dun Development Corporation, the active business arm of the First Nation of Na-Cho Nyäk Dun. This means that our profits are reinvested back into the community and participate in fostering the wellbeing of Na-Cho Nyäk Dun people, land, and waters.
                <br></br>
                Being First Nation owned also means that Cold Acre is an important participant in strengthening Indigenous self-determination and food sovereignty. Our growing solutions don’t just mean that Indigenous communities have improved access to quality and affordable foods, but the foods grown are culturally relevant and wanted. We’re focused on working closely with our Indigenous clients to address unique community needs and ensure that food may continue to be a catalyst for cultural reignition and vibrancy.
              </p>
            </div>
          </div>
        </PaddedPage>
        {/* <PaddedPage className="flex relative z-10 gap-4 flex-col items-center">
          <h1>Our Team</h1>
          <h3 className="max-w-160">
            ColdAcre was born from the North. We know the life, but we also know the challenges that government officials in Ottawa just aren’t privy to. 
            <br></br>
            <br></br>
            Many of us grew up in Yukon, so we know what it’s like to pay exorbitant amounts for poor, rotting produce. This is our fight, and our mission is to look for a sustainable food source for our Northern communities. 
          </h3>
          <br></br>
          <div className="flex flex-wrap gap-2">
            <div className="w-60 h-60 bg-primary rounded-full">
            </div>
            <div className="w-60 h-60 bg-primary rounded-full">
            </div>
            <div className="w-60 h-60 bg-primary rounded-full">
            </div>
            <div className="w-60 h-60 bg-primary rounded-full">
            </div>
            <div className="w-60 h-60 bg-primary rounded-full">
            </div>
            <div className="w-60 h-60 bg-primary rounded-full">
            </div>
            <div className="w-60 h-60 bg-primary rounded-full">
            </div>
            <div className="w-60 h-60 bg-primary rounded-full flex flex-col justify-center items-center">
              <h3 className="font-bold text-white text-xl">Connect with Us</h3>
              <div className="flex text-white gap-2">
                <FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon>
                <FontAwesomeIcon icon={faLinkedin}></FontAwesomeIcon>
                <FontAwesomeIcon icon={faFacebook}></FontAwesomeIcon>
              </div>
            </div>
          </div>
        </PaddedPage> */}
        <PaddedPage>
          <div className="rounded-2xl h-oval w-full bg-cover bg-center gap-8 flex flex-col justify-center items-center" style={{backgroundImage: `url(${about})`}}>
            <div className="backdrop-brightness-75 flex flex-col items-center py-8">
              <h1 className="text-white">Let's get you growing!</h1>
              <h2 className="text-white">There's a customized system waiting to be built for your community.</h2>
            </div>
            <Link to="/order"><ButtonPrimary className="relative bottom-2 p-12 px-10 font-bold border-white bg-white">Start Building Today</ButtonPrimary></Link>     
          </div>
        </PaddedPage>
        <PaddedPage>
          <div className="rounded-2xl h-oval w-full bg-cover bg-center gap-8 flex flex-col justify-center items-center" style={{backgroundImage: `url(${sub})`}}>
            <div className="backdrop-brightness-50 flex items-center justify-center w-full">
              <div className="flex flex-col items-center max-w-160 text-center py-8">
                <h1 className="text-white">Not in the market for a system but love what we do? </h1>
                <h2 className="text-white">Reap the benefits of our in-house operations and get fresh produce and local products sent right to your door with WinterHarvest by ColdAcre - the Yukon's Local Food Subscription Box. </h2>
              </div>
            </div>
            <a href="https://winterharvest.coldacre.ca" target="_blank"><ButtonPrimary className="relative bottom-2 p-12 px-10 font-bold border-white bg-white">I want in!</ButtonPrimary></a>     
          </div>
        </PaddedPage>
      </div>
    </div>
  );
};

export default AboutPage;
