import React from 'react';
import { Routes } from 'react-router-dom';

import LandingScreens from './landing';
import AuthScreens from './auth';
import NotFoundScreens from './notFound';
import NotAuthorizedScreens from './notAuthorized';
import PrefabScreens from './prefab';
import WinterHarvestScreens from './winterHarvest';
import OrderScreens from './order';
import AboutScreens from './about';
import ContactScreens from './contact';
import PlusScreens from './plus';

const Router = () => {
  return (
    <Routes>
      {
        [   
          ...LandingScreens,
          ...PrefabScreens,
          ...AuthScreens,
          ...NotAuthorizedScreens,
          ...WinterHarvestScreens,
          ...OrderScreens,
          ...NotFoundScreens,
          ...AboutScreens,
          ...ContactScreens,
          ...PlusScreens
        ]
      }
    </Routes>
  );
};

export default Router;
