import React, { useContext } from 'react';
import { PaddedPage } from 'components/common/Layout';
import { Link } from 'react-router-dom';
import appContext from 'contexts/AppContext';

const NotAuthorized = () => {
  const { online } = useContext(appContext);
  return (
    <PaddedPage>
      {online ?
        <>
          <h1>You are not authorized to do this.</h1>
          <p>Make sure you are <Link to="/login">logged in</Link> and have the right access.</p>
        </>
        :
        <>
          <h1>You must be online to do this.</h1>
        </>
      }
    </PaddedPage>
  );
};

export default NotAuthorized;
