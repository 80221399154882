import React from 'react'
import FAQuestion from './FAQuestion';
import { gql } from "@apollo/client";
import useContentfulRequest from 'hooks/useContentfulRequest';


const GET_FAQS = gql`
  query getFAQs($page: String!) {
    faqCollection(where: {page: $page }) {
        items {
            question
            answer {
                json
            }
        }
    }
  }
`;

export default function SubscriptionFAQ({page}) {
    const region = page || "whitehorse";
    const {data} = useContentfulRequest({query: GET_FAQS, variables: { page: region}});
    let faqCollection = data ? [...data.faqCollection.items] : [];
    let faqs = faqCollection.reverse().map((question, index) => {
        return (
            <FAQuestion key={index} startOpen={index === 0} question={question}/>
        );
    });

    return (
        <div className="flex flex-col gap-4">
            <h1>Subscription FAQ's</h1>
            <div className="flex flex-col gap-2">
                {faqs}
            </div>
        </div>
    )
}
