import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import ButtonPrimary from 'components/common/Button';
import Transparent from './Transparent';

export default function SuccessNotice({title, message, submessage, onClose, onConfirm}) {
  let [open, setOpen] = useState(true);

  const close = () => {
    setOpen(false);
    onClose();
  }

  return (
    <>
      {
        open &&
        <Transparent>
          <div onClick={close} className="absolute flex justify-center items-center w-full h-full">
            <div onClick={(e) => e.stopPropagation()} className="bg-white rounded border max-w-7/8 w-120 shadow-md">
              <div className={`flex justify-between relative px-4 py-2 bg-green`}>
                <h2 className="text-white">
                  <FontAwesomeIcon onClick={close} className="" icon={faCheck} />
                  &nbsp;
                  {title}
                </h2>
                <FontAwesomeIcon onClick={close} className="text-white cursor-pointer absolute top-1 right-2" icon={faTimes} />
              </div>
              <div className="p-4 bg-whiter py-8 flex flex-col gap-2">
                <p>
                  {message}
                </p>
                {submessage && <p>{submessage}</p>}
              </div>
              <div className="p-4 bg-whiter flex justify-end border-t gap-2 py-2">
                <ButtonPrimary onClick={close} >Close</ButtonPrimary>
              </div>
            </div>
          </div>
        </Transparent>
      }
    </>
  )
}
