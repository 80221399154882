import React, {useContext, useCallback, useEffect, useRef} from 'react';
import { PaddedPage } from 'components/common/Layout';
import AppContext from 'contexts/AppContext';
import ButtonPrimary from 'components/common/Button';
import pile1 from 'assets/images/prefab/Pile.png';
import pile2 from 'assets/images/prefab/Pile2.png';
import pile3 from 'assets/images/prefab/Pile3.png';
import farm from 'assets/images/prefab/farm.jpg';
import { Link } from 'react-router-dom';

const PrefabSizes = () => {
  const {setPage} = useContext(AppContext);

  useEffect(() => setPage("Prefab Units"), [setPage]);

  return (
    <div className="bg-cover pt-12 bg-top" id="production" style={{backgroundImage: `url(${farm})`}}>
        <PaddedPage className="flex flex-col items-center gap-6">
          <div>
            <h1>Units that fit your community's needs</h1>
            <h2>All primary systems are available in three sizes </h2>
          </div>
          <div className="flex justify-around w-full sm:flex-col">
            <div className="w-1/4 sm:w-full flex flex-col items-center text-center">
              <div className="w-44 h-40 mb-4 bg-contain bg-center bg-no-repeat" style={{backgroundImage: `url(${pile1})`}}></div>
              <h1 className="text-white 2xl:text-black sm:text-black">Hydroponic6</h1>
              <div className="bg-transparent-secondary rounded-xl p-4">
                <h2 className="mb-2 text-white">115<sub>lbs<br></br> greens/week</sub></h2>
                <p className="text-white">Feeds 80-100 households - Community Size 200-300 people</p>
              </div>
            </div>
            <div className="w-1/4 sm:w-full flex flex-col items-center text-center">
              <div className="w-52 h-40 mb-4 bg-contain bg-center bg-no-repeat" style={{backgroundImage: `url(${pile2})`}}></div>
              <h1 className="text-white 2xl:text-black">Hydroponic12</h1>
              <div className="bg-transparent-secondary rounded-xl p-4">
                <h2 className="mb-2 text-white">230<sub>lbs<br></br> greens/week</sub></h2>
                <p className="text-white">Feeds 160-200 households - Community Size 600 people</p>
              </div>
            </div>
            <div className="w-1/4 sm:w-full flex flex-col items-center text-center">
              <div className="w-60 h-40 mb-4 bg-contain bg-center bg-no-repeat" style={{backgroundImage: `url(${pile3})`}}></div>
              <h1 className="text-white 2xl:text-black">Hydroponic15</h1>
              <div className="bg-transparent-secondary rounded-xl p-4">
                <h2 className="mb-2 text-white">300<sub>lbs<br></br> greens/week</sub></h2>
                <p className="text-white">Feeds 160-250 households - Community Size 600-1000 people</p>
              </div>
            </div>
          </div>
          <div className="w-2/3 flex items-center flex-col">
            <Link to="/order">
              <ButtonPrimary className="mt-2 text-dark bg-white px-20" >Start Building</ButtonPrimary>
            </Link>
          </div>
        </PaddedPage>
    </div>
  );
};

export default PrefabSizes;
