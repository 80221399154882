import React, {useContext} from 'react';
import RequestFlagsContext from 'contexts/RequestFlagsContext';
import { ApolloProvider, ApolloClient, from, InMemoryCache } from '@apollo/client';
import {
  httpLink,
  setAuthHeaders,
  saveAuthHeaders,
  authAfterware
} from 'middleware';

const ApolloClientProvider = ({ children }) => {
  const {addError} = useContext(RequestFlagsContext);

  const client = new ApolloClient({
    uri: `${process.env.REACT_APP_API_URL}/graphql?`,
    link: from([
        setAuthHeaders,
        saveAuthHeaders, 
        authAfterware({addError}),
        httpLink,
    ]),
    cache: new InMemoryCache()
  });

  return (
    <ApolloProvider client={client}>
        {children}
    </ApolloProvider>
  );
};

export default ApolloClientProvider;
