import React from 'react';
import { Route } from 'react-router-dom';
import WinterHarvestInfo from './WinterHarvestInfo';
import SubscriptionRoutes from './subscriptions';

const routes = [
  <Route key="winterharvest" path="/winterharvest" element={<WinterHarvestInfo/>} />,
  ...SubscriptionRoutes
];

export default routes;
