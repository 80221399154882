import React, {useContext, useEffect, useState} from 'react';
import { PaddedPage } from 'components/common/Layout';
import AppContext from 'contexts/AppContext';
import contact from 'assets/images/contact/contact.jpg';
import bottom from 'assets/images/contact/bottom.jpg';

import ButtonPrimary from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faCompass } from '@fortawesome/free-regular-svg-icons';
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelopeOpenText, faLeaf, faMailForward, faMobile, faMoneyBill, faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import Loading from 'components/common/Loading';
import SuccessNotice from 'components/notices/SuccessNotice';

const CONTACT_US = gql`
  mutation ContactInfo($email: String!, $name: String, $organization: String, $phoneNumber: String, $type: String, $content: String ) {
    contactInfo(email: $email, name: $name, organization: $organization, phoneNumber: $phoneNumber, type: $type, content: $content)
  }
`;

const ContactPage = () => {
  const {setPage} = useContext(AppContext);
  useEffect(() => setPage("contact"), [setPage]);
  const [contactInfo, setContactInfo] = useState({});
  const [showSuccess, setShowSuccess] = useState();
  const [contactUsMutation, { loading }] = useMutation(CONTACT_US);

  const deliver = () => {
    contactUsMutation({variables: {...contactInfo}})
    .then(res => {
      setShowSuccess(true);
    });
  }

  const valid = () => {
    return contactInfo.email && contactInfo.content;
  }
  return (
    <>
      {showSuccess && 
        <SuccessNotice
          title="Success!"
          message="We will be in contact with you soon."
          onClose={() => setShowSuccess(false)}
        />
      }
      <div className="flex flex-col">
        <div className="bg-cover bg-center bg-no-repeat" style={{backgroundImage: `url(${contact})`}}>
          <div className="backdrop-brightness-75">
            <PaddedPage className="flex h-20vh" >
              <div className="w-2/3 flex flex-col justify-center items-start gap-8">
                  <h1 className="jumbo text-white backdrop-brightness-90">
                    Contact
                  </h1>
              </div>
            </PaddedPage>
          </div>
        </div>
        <PaddedPage className="flex gap-8 sm:flex-col items-start">
          <div>
            <h2>Message us</h2>
            <div className="flex flex-col gap-2 w-96">
              <div className="flex flex-col">
                <label>Name</label>
                <input value={contactInfo.name} onChange={(e) => setContactInfo({...contactInfo, name: e.target.value})} className="border-background-dark border-2 rounded p-2"/>
              </div>
              <div className="flex flex-col">
                <label>Organization</label>
                <input value={contactInfo.organization} onChange={(e) => setContactInfo({...contactInfo, organization: e.target.value})} className="border-background-dark border-2 rounded p-2"/>
              </div>
              <div className="flex flex-col">
                <label>Email</label>
                <input value={contactInfo.email} onChange={(e) => setContactInfo({...contactInfo, email: e.target.value})} className="border-background-dark border-2 rounded p-2"/>
              </div>
              <div className="flex flex-col">
                <label>Phone Number</label>
                <input value={contactInfo.phoneNumber} onChange={(e) => setContactInfo({...contactInfo, phoneNumber: e.target.value})} className="border-background-dark border-2 rounded p-2"/>
              </div>
              <div className="flex flex-col">
                <div className="flex gap-2">
                  <input checked={contactInfo.type == "existing"} onChange={(e) => setContactInfo({...contactInfo, type: "existing"})} type="radio" id="existing" name="fav_language" value="HTML"/>
                  <label className="text-base" for="existing">Existing Customer</label>
                </div>
                <div className="flex gap-2">
                  <input checked={contactInfo.type == "new"} onChange={(e) => setContactInfo({...contactInfo, type: "new"})} type="radio" id="new" name="fav_language" value="CSS"/>
                  <label className="text-base" for="new">New Customer</label>
                </div>
              </div>
              <div className="flex flex-col">
                <label>How can we help?</label>
                <textarea value={contactInfo.content} onChange={(e) => setContactInfo({...contactInfo, content: e.target.value})} className="border-background-dark border-2 rounded p-2"/>
              </div>
              <br></br>
              <ButtonPrimary onClick={() => deliver()} disabled={!valid() || loading}>{loading ? <Loading/> : "Send"}</ButtonPrimary>
            </div>
          </div>
          <div className="flex flex-col gap-8 rounded bg-light-grey mt-14 sm:mt-0 p-4 border-background-dark">
            <div className="flex gap-4">
              <FontAwesomeIcon className="text-5xl" icon={faEnvelopeOpenText}/>
              <div className="flex flex-col gap-2">
                <div>
                  <h2>General Inquiries</h2>
                  <p className="text-xl">info@ColdAcre.ca</p>
                </div>
                <div>
                  <h2>
                    Quotes + sales
                  </h2>
                  <p className="text-xl">
                    sales@ColdAcre.ca
                  </p>
                </div>
              </div>
            </div>
            <div className="flex gap-4 items-center">
              <FontAwesomeIcon className="text-5xl" icon={faMobile}/>
              <div>
                <h2>
                  Tarek Bos-Jabbar | COO
                </h2>
                <p className="text-xl">
                  867-332-4989
                </p>
              </div>
            </div>
            <div className="flex gap-4 items-center">
              <FontAwesomeIcon className="text-5xl" icon={faCompass}/>
              <div>
                <h2>
                  Whitehorse
                </h2>
                <p className="text-xl">
                  110 Titanium Way
                  <br></br>
                  Whitehorse, Yukon
                  <br></br>
                  Canada
                  <br></br>
                  Y1A 0E8
                </p>
              </div>
            </div>
          </div>
        </PaddedPage>
        <div className="bg-cover bg-center bg-no-repeat" style={{backgroundImage: `url(${bottom})`}}>
          <div className="backdrop-brightness-50 flex items-center justify-center">
            <PaddedPage className="flex justify-center">
              <div className="flex flex-col items-center text-center">
                <h1 className="text-white">Ready to get started? <FontAwesomeIcon icon={faLeaf} /></h1>
                <h2 className="text-white">Start building your system with our Northern System Builder today and submit an estimate request to secure your spot in our production lineup.</h2>
                <br></br>
                <Link to="/order">
                  <ButtonPrimary className="mt-2 w-full" white >Start Buiding</ButtonPrimary>
                </Link>
              </div>
            </PaddedPage>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactPage;
