import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () =>  {
  const location = useLocation();
  useEffect(() => {
    if (!location.state || !location.state.scroll) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return <> </>;
};

export default ScrollToTop;
