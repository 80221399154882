export const features = [
    {
      id: "arcticEntrance",
      name: "Arctic Entrance", 
      cost: "15000",
      description: "A food grade, CFIA compliant entrance which includes a handwashing sink with hot and cold water, and a stainless steel counter top.",
      bullets: [
        'Includes CFIA compliant processing and packing area',
      ],
      titleStyle:"gradient",
      showCalculation: "order.size == 16",
      meta: {
        power: 250,
        production: -100,
        crops: [],
        additional: ['GAP compliant Facility']
      }
    },
    {
      id: "refrigeratedArcticEntrance",
      name: "Refrigerated Arctic Entrance", 
      cost: "5000",
      showCalculation: "order.arcticEntrance",
      description: "Increase shelf life and maintain your cold-chain management by turning your Arctic Entrance into a walk-in fridge.",
      bullets: [
        'Refrigerated Arctic Entrance',
        'Increase Power Consumption by 500kwh/month in Summer months',
      ],
      titleStyle:"gradient",
      meta: {
        power: 500,
        production: 0,
        crops: [],
        additional: ["Walk-in Fridge"]
      }
    },
    {
      id: "1600LReservoir",
      name: "1600L Reservoir", 
      cost: "2000",
      showCalculation: "order.arcticEntrance",
      description: "Perfect for off grid systems. This custom fit reservoir fits perfectily in the Arctic Entrance. This allows you to fill up on water once per week.",
      titleStyle:"gradient",
      meta: {
        power: 0,
        production: 0,
        crops: []
      }
    },
    {
      id: "greenhouse",
      name: "Greenhouse", 
      cost: "10000",
      description: "Utilizing waste heat and smart design, this system allows you to grow crops such as tomatoes, cucumbers, peppers, etc. For 3 seasons.",
      bullets: [
        'Increased crop options',
        'Grow more during Summer months',
      ],
      titleStyle:"gradient",
      options: [
        {     
          id: "greenhouse6",
          optionName: "6 meters",
          cost: '30000',
          meta: {
            power: 0,
            production: 75,
            crops: ["Cucumbers", "Peppers", "Tomatoes"]
          }
        },
        {     
          id: "greenhouse12",
          optionName: "12 meters",
          cost: '55000',
          showCalculation: "order.size >= 12",
          meta: {
            power: 0,
            production: 150,
            crops: ["Cucumbers", "Peppers", "Tomatoes"]
          }
        },
        {     
          id: "greenhouse15",
          optionName: "15 meters",
          cost: '70000',
          showCalculation: "order.size == 15",
          meta: {
            power: 0,
            production: 200,
            crops: ["Cucumbers", "Peppers", "Tomatoes"]
          }
        },
      ]
    },
    {
      id: "solar1",
      name: "Solar Package", 
      cost: "12500",
      showCalculation: "order.size == 6",
      description: "Installed by Solvest Inc. during manufacturing, a solar array has the ability to offset 50% of your power consumption. This system would be used to offset power only. It is not a substitute for grid-tied power. For off-grid systems, a customer service representative will help.",
      bullets: [
        'Reduce power by 216kw/h per month',
      ],
      titleStyle:"gradient",
      meta: {
        power: -216,
        production: 0,
        crops: []
      }
    },
    {
      id: "solar2",
      name: "Solar Package", 
      cost: "12500",
      showCalculation: "order.size == 12",
      description: "Installed by Solvest Inc. during manufacturing, a solar array has the ability to offset 50% of your power consumption. This system would be used to offset power only. It is not a substitute for grid-tied power. For off-grid systems, a customer service representative will help.",
      bullets: [
        'Reduce power by 483kw/h per month',
      ],
      titleStyle:"gradient",
      meta: {
        power: -483,
        production: 0,
        crops: []
      }
    },
    {
      id: "solar3",
      name: "Solar Package", 
      cost: "12500",
      showCalculation: "order.size == 15",
      description: "Installed by Solvest Inc. during manufacturing, a solar array has the ability to offset 50% of your power consumption. This system would be used to offset power only. It is not a substitute for grid-tied power. For off-grid systems, a customer service representative will help.",
      bullets: [
        'Reduce power by 585kw/h per month',
      ],
      titleStyle:"gradient",
      meta: {
        power: -585,
        production: 0,
        crops: []
      }
    },
    {
      id: "customWrap",
      name: "Custom Wrap",
      meta: {
        power: 0,
        production: 0,
        crops: []
      },
      options: [
        {     
          id: "wrap1side",
          optionName: "Wrap 1 Side",
          description: "Wrap half your system to showclase your logo, or local artwork.",
          cost: "order.size == 6 ? '3,500' : (order.size == 12 ? 600 : 7500)"
        },
        {     
          id: "wrap2side",
          description: "Wrap the entire system to showcase your logo, or local artwork.",
          optionName: "Wrap 2 Sides",
          cost: "order.size == 6 ? 6500 : (order.size == 12 ? 1000 : 12000)",
        },
        {     
          id: "wrapbranding",
          description: "Showcase your logo beside the ColdAcre logo.",
          optionName: "Regular Branding",
          cost: '500',
        },
      ]
    },
    {
      id: "growingShelf",
      name: "Grow Shelves", 
      description: "Add 600 planting spaces per shelf to your system to grow lettuce, herbs, mustard greens, kales, chard, arugula, etc.",
      bullets: [
        // 'Increased crop options',
        // 'Grow more during Summer months',
      ],
      titleStyle:"gradient",
      options: [
        {     
          id: "1",
          optionName: "1 Growing Shelf",
          cost: '22500',
          meta: {
            size: 1,
            power: 960,
            production: 62.5,
            crops:  ["Lettuce", "Herbs", "Mustard Greens", "Kale", "Chard", "Arugula"],
          }
        },
        {     
          id: "2",
          optionName: "2 Growing Shelves",
          cost: '45000',
          meta: {
            size: 2,
            power: 1920,
            production: 125,
            crops:  ["Lettuce", "Herbs", "Mustard Greens", "Kale", "Chard", "Arugula"],
          }
        },
        {     
          id: "3",
          optionName: "3 Growing Shelves",
          cost: '67500',
          showCalculation: "Number(order.size) > 6",
          meta: {
            size: 3,
            power: 2880,
            production: 187.5,
            crops:  ["Lettuce", "Herbs", "Mustard Greens", "Kale", "Chard", "Arugula"],
          }
        },
        {     
          id: "4",
          optionName: "4 Growing Shelves",
          cost: '90000',
          showCalculation: "Number(order.size) > 6",
          meta: {
            size: 4,
            power: 3840,
            production: 250,
            crops:  ["Lettuce", "Herbs", "Mustard Greens", "Kale", "Chard", "Arugula"],
          }
        },
        {     
          id: "5",
          optionName: "5 Growing Shelves",
          cost: '100000',
          showCalculation: "Number(order.size) > 12",
          meta: {
            size: 5,
            power: 4540,
            production: 290,
            crops:  ["Lettuce", "Herbs", "Mustard Greens", "Kale", "Chard", "Arugula"],
          }
        },
        {     
          id: "6",
          optionName: "6 Growing Shelves",
          cost: '110000',
          showCalculation: "Number(order.size) > 12",
          meta: {
            size: 6,
            power: 5240,
            production: 330,
            crops:  ["Lettuce", "Herbs", "Mustard Greens", "Kale", "Chard", "Arugula"],
          }
        },
      ]
    },
    {
      id: "trainingPackage",
      name: "ColdAcre Training and Workplacement", 
      cost: "12500",
      description: "Highly recommended, our top tier training program lasts two weeks and teaches you via a work placement on our commercially succesful farm. Attendance increases start up efficiency by 50%-100%. Maximum of two, accomodation Included. Flight not included.",
      bullets: [
        'Reduce power to 3950 kwh/month',
      ],
      meta: {
        power: 0,
        production: 0,
        crops: [],
        additional: ["Top tier hands-on training at the ColdAcre Facilty"]
      }
    },
    {
      id: "fullStartUpPackage",
      name: "Full Start-up Package", 
      cost: "2000",
      description: "Start off on the right step with the same harvesting bins, impulse sealers, packaging, label makers and delivery bins used at ColdAcre",
      meta: {
        power: 0,
        production: 0,
        crops: [],
        additional: []
      }
    },
    {
      id: "automatedDosing",
      name: "Automated Dosing", 
      showCalculation: "order.growingShelf",
      cost: "Number(order.growingShelf.meta.size) * 1500/2",
      description: "Concerned about dosing? We can include an intellidose system which will keep your pH and EC levels at pre-set levels.",
      meta: {
        power: 0,
        production: 0,
        crops: [],
        additional: []
      },
    },

    {
      id: "growMedium",
      name: "1 year Growing Medium", 
      showCalculation: "order.growShelf",
      cost: "Number(order.growShelf.meta.size) * 375",
      description: "Growing medium sent directly to your operation annually",
      meta: {
        power: 0,
        production: 0,
        crops: [],
        additional: []
      },
    },
    

    {
      id: "nutrients",
      name: "1 year Nutrients", 
      showCalculation: "order.growShelf",
      cost: "Number(order.growShelf.meta.size) * 375",
      description: "Growing medium sent directly to your operation annually",
      meta: {
        power: 0,
        production: 0,
        crops: [],
        additional: []
      },
    },
    

    {
      id: "acUnit",
      name: "AC Unit", 
      cost: "order.size == 6 ? '2,500' : (order.size == 12 ? '3,500' : '4,500')",
      description: "Perfect if you want to operate your unit in the summer in a warm climate. ",
      bullets: [
        'Increase by 500 kwh/month in Summer months',
      ],
      meta: {
        power: "order.size == 6 ? 500 : (order.size == 12 ? 1000 : 1350)",
        production: 0,
        crops: [],
        additional: []
      }
    },
  ]