import React from 'react';
import ButtonPrimary from './Button';

export default function Confirm({open, text, onConfirm, onCancel}) {
  return (
    <>
        {open &&
            <div className="bg-white absolute">
                {text}
                <div className="flex">
                    <ButtonPrimary onclick={onConfirm}>Confirm</ButtonPrimary>
                    <ButtonPrimary onclick={onCancel}>Cancel</ButtonPrimary>
                </div>
            </div>
        }
    </>
  )
}
