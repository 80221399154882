import { useState, useEffect, useCallback } from 'react';

function isObject(value) {
  return !(value instanceof Date) && !Array.isArray(value) && !Object.is(value, null) && !Object.is(value, undefined) && !(value instanceof Function)
}

const useErrors = () => {
  const [errors, setErrors] = useState({});

  const removeError = (id) => {
    let newErrors = {...errors};
    delete newErrors[id];
    setErrors(newErrors);
  };

  const addError = useCallback((error) => {
    if (!isObject(error)) {
      error = { message: error };
    }
    let key =  error.code || error.statusCode || Math.floor(Math.random() * 10000);
    setErrors(errors => {
      let newErrors = {...errors};
      newErrors[key] = {...error, key};
      return newErrors;
    });
  }, []);

  return {
        errors,
        currentError: Object.entries(errors)[0],
        addError,
        unauthorized: Object.keys(errors).includes('401'),
        removeError
  };
};

export default useErrors;
