import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import appContext from 'contexts/AppContext';

const AnonRoute = ({ children, path }) => {
  const { currentUser } = useContext(appContext);
  const location = useLocation();
  if (currentUser) {
    return  <Navigate to={{
      pathname:'/',
      state: {
        message: 'You are already logged in',
        from: location.pathname || path
      }
    }}/>
  }

  return children;
};

export default AnonRoute;
