import React, {useContext, useCallback, useEffect, useRef} from 'react';
import { PaddedPage } from 'components/common/Layout';
import AppContext from 'contexts/AppContext';
import ButtonPrimary from 'components/common/Button';
import farm from 'assets/images/prefab/farm.jpg';
import ModelDiagram from './ModelDiagram';
import isocontainer from 'assets/images/prefab/isocontainer.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDroplet, faSeedling, faSquareCheck } from '@fortawesome/free-solid-svg-icons';

const PrefabUnits = () => {
  const {setPage} = useContext(AppContext);
  const canvasRef = useRef();
  const parentRef = useRef();

  useEffect(() => setPage("Prefab Units"), [setPage]);
  
  // const drawCanvas = () => {
  //   let ctx = canvasRef.current.getContext('2d');
  //   let width = canvasRef.current.width;
  //   let height = width;
  //   ctx.beginPath();     // Start a new path.
  //   ctx.lineWidth = "2";
  //   ctx.strokeStyle = "#27B67A";  // This path is green.
  //   ctx.arc(width*0.3, height/2, width * 0.01, 0, 2 * Math.PI);
  //   ctx.moveTo(width*0.3, height/2);
  //   ctx.lineTo(width * 0.4, height * 0.7);
  //   ctx.lineTo(width * 0.42, height * 0.7);
  //   ctx.font = "20px Arial";
  //   ctx.fillStyle = "white";
  //   ctx.fillText("Automated Planting Trays", width * 0.42, height * 0.7);
  //   ctx.stroke();

  //   ctx.beginPath();
  //   ctx.arc(width * 0.15, height * 0.5, width * 0.01, 0, 2 * Math.PI);
  //   ctx.moveTo(width * 0.15, height * 0.5);
  //   ctx.lineTo(width * 0.2, height * 0.1);
  //   ctx.lineTo(width * 0.23, height * 0.1);
  //   ctx.textAlign = "start";
  //   ctx.fillText("Arctic Entrance & Produce Cooler", width * 0.23, height * 0.1);
  //   ctx.stroke();  

  //   ctx.beginPath();
  //   ctx.arc(width * 0.95, height * 0.4, width * 0.01, 0, 2 * Math.PI);
  //   ctx.moveTo(width * 0.95, height * 0.4);
  //   ctx.lineTo(width * 0.9, height * 0.25);
  //   ctx.lineTo(width * 0.875, height * 0.25);
  //   ctx.textAlign = "end";
  //   ctx.fillText("HVAC", width * 0.875, height * 0.25);
  //   ctx.stroke();  
    
  //   ctx.closePath(); // Close the current path.
  // }

  // const windowResize = useCallback(() => {
  //     if (canvasRef.current) {
  //         canvasRef.current.width  = parentRef.current.clientWidth;
  //         canvasRef.current.height = parentRef.current.clientWidth;
  //         drawCanvas();
  //   }
  // }, []);

  // useEffect(() => {
  //   window.addEventListener('resize', windowResize);
  //   windowResize();
  // }, [windowResize]);

  return (
    <div className="bg-dark" id="build">
      <PaddedPage className="flex gap-8 items-center sm:flex-col" >
        <div className="w-full relative" ref={parentRef}>
          {/* <ModelDiagram parentRef={parentRef}></ModelDiagram> */}
          {/* <img src={isocontainer} className="mb-10 mt-20vw 2xl:mt-64 sm:mt-25vw" alt="isometric view of conatiner farm"/>
          <canvas ref={canvasRef} className="absolute top-0 left-0" id="myCanvas" width="300" height="600"></canvas> */}
          <div className="flex flex-col gap-8 w-full">
              <table className="w-full">
                <tr className="">
                  <th></th>
                  <th className="text-white p-2">Hydroponics</th>
                  <th className="text-white p-2">Traditional Farming</th>
                </tr>
                <tr className="text-center bg-light-grey">
                  <td className="font-medium text-left p-1">Water usage</td>
                  <td className="text-2xl"><FontAwesomeIcon className="text-primary" icon={faDroplet}/></td>
                  <td className="text-2xl">
                    <FontAwesomeIcon className="text-primary" icon={faDroplet}/>
                    <FontAwesomeIcon className="text-primary" icon={faDroplet}/>
                    <FontAwesomeIcon className="text-primary" icon={faDroplet}/>
                    <FontAwesomeIcon className="text-primary" icon={faDroplet}/>
                    <FontAwesomeIcon className="text-primary" icon={faDroplet}/>  
                  </td>
                </tr>
                <tr className="text-center bg-white">
                  <td className="font-medium text-left p-1">Density</td>
                  <td className="text-2xl">
                    <FontAwesomeIcon className="text-primary-two" icon={faSeedling}/>
                    <FontAwesomeIcon className="text-primary-two" icon={faSeedling}/>
                    <FontAwesomeIcon className="text-primary-two" icon={faSeedling}/>
                  </td>
                  <td className="text-2xl">
                    <FontAwesomeIcon className="text-primary-two" icon={faSeedling}/>
                  </td>
                </tr>
                <tr className="text-center bg-light-grey">
                  <td className="font-medium text-left p-1">Weather Dependent</td>
                  <td></td>
                  <td className="text-2xl"><FontAwesomeIcon className="text-primary-two" icon={faSquareCheck}/></td>
                </tr>
                <tr className="text-center bg-white">
                  <td className="font-medium text-left p-1">Safe Produce</td>
                  <td className="text-2xl"><FontAwesomeIcon className="text-primary-two" icon={faSquareCheck}/></td>
                  <td className="text-2xl"><FontAwesomeIcon className="text-primary-two" icon={faSquareCheck}/></td>
                </tr>
                <tr className="text-center bg-light-grey">
                  <td className="font-medium text-left p-1">Requires Electricity</td>
                  <td className="text-2xl"><FontAwesomeIcon className="text-primary-two" icon={faSquareCheck}/></td>
                  <td></td>
                </tr>
                <tr className="text-center bg-white">
                  <td className="font-medium text-left p-1">Crop Rotation</td>
                  <td></td>
                  <td className="text-2xl"><FontAwesomeIcon className="text-primary-two" icon={faSquareCheck}/></td>
                </tr>
              </table>
            </div>
        </div>
        <div className="w-1/2 flex flex-col z-10 sm:w-full">
            <h1 className="text-white">Hydroponics</h1>
            <h2 className="text-white">A soilless growing technique</h2>
            <br></br>
            <p className="text-white">
              ColdAcre Hydroponic Systems use nutrient enriched water and light systems to deliver everything that the plants need to grow. As a result, our technology typically sees growth at twice the speed of traditional growing techniques.
              <br></br>
              <br></br>
              Hydroponics are very effective for the growth of leafy greens, herbs, and fruiting bodies such as vegetables. ColdAcre Hydropinic Systems focus specifically on leafy greens and herbs, which we pair with our Optimize Greenhouse+ Package for communities looking to include fruiting crops and root vegetables. 
            </p>
            <br></br>
            <Link to="/order" className="w-full">
              <ButtonPrimary className="mt-2 w-full" white >Explore the Possibilities</ButtonPrimary>
            </Link>
        </div>
        </PaddedPage>
    </div>
  );
};

export default PrefabUnits;
