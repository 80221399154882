import React from 'react';
import { PaddedPage } from 'components/common/Layout';
import { HeadingOne, HeadingThree, TextBodyXLg } from 'components/common/Textbox';
import {ButtonPrimary} from 'components/common/Button';

const NotFound = () => {
  return (
    <PaddedPage>
      <div className="
        sm:py-6 
        md:py-24 
        text-center
        "
      >
        <div className="
          flex
          flex-col
          items-center
          gap-6
        "
        >
          <HeadingOne>404</HeadingOne>
          <HeadingThree>Page Not Found</HeadingThree>
          <TextBodyXLg>We&apos;re sorry, the page you requested could not be found. Please go back to the homepage or contact us at <b>support@choro.ca</b>.</TextBodyXLg>
          <ButtonPrimary to="/">Return to Home</ButtonPrimary>
        </div>
      </div>
    </PaddedPage>
  );
};

export default NotFound;
