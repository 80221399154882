import React, {useState, useContext} from 'react';
import { PaddedPage } from 'components/common/Layout';
import {Input, Label} from 'components/inputs';
import {ButtonPrimary} from 'components/common/Button';
import { Link } from 'react-router-dom';
import AppContext from 'contexts/AppContext';

const Login = () => {
  const {login} = useContext(AppContext);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const handleLogin = () => {
    login(email, password);
  }
  
  return (
    <PaddedPage>
      <div className="flex flex-col justify-center items-center gap-2">
        <h1>Login</h1>
        <div className="flex flex-col">
          <label>email</label>
          <Input onChange={setEmail}/>
        </div>
        <div className="flex flex-col">
          <label>password</label>
          <Input type="password" onChange={setPassword}/>
        </div>
        <p>
          Dont have an account? Sign Up <Link to="/sign-up">Here</Link>
        </p>
        <ButtonPrimary onClick={handleLogin}>Submit</ButtonPrimary>
      </div>
    </PaddedPage>
  );
};

export default Login;
