import React from 'react'; 

const TextBodyXLg = ({ children }) => {
  return (
    <p className="
      text-lg
      " 
    >
      {children}
    </p>
  );
};

export default TextBodyXLg;
