import React, {useContext, useState, useEffect, useMemo} from 'react';
import { PaddedPage } from 'components/common/Layout';
import AppContext from 'contexts/AppContext';
import ButtonPrimary from 'components/common/Button';
import {Input} from 'components/inputs';
import Modal from 'components/common/Modal';
import PaymentForm from 'components/payments/PaymentForm';
import SizeSelector from './SizeSelector';
import AddFeature from './AddFeature';
import { useMutation, gql } from "@apollo/client";
// build images
import build6 from 'assets/images/prefab/6/6.png';
import build6GH from 'assets/images/prefab/6/6GH.png';
import build6GHsolar from 'assets/images/prefab/6/6GHsolar.png';
import build6solar from 'assets/images/prefab/6/6solar.png';
import build12 from 'assets/images/prefab/12/12.png';
import build12GH from 'assets/images/prefab/12/12GH.png';
import build12GHsolar from 'assets/images/prefab/12/12GHsolar.png';
import build12solar from 'assets/images/prefab/12/12solar.png';
import build16 from 'assets/images/prefab/16/16.png';
import build16GH from 'assets/images/prefab/16/16GH.png';
import build16GHsolar from 'assets/images/prefab/16/16GHsolar.png';
import build16solar from 'assets/images/prefab/16/16solar.png';
import build16AE from 'assets/images/prefab/16/16AE.png';
import build16GHAE from 'assets/images/prefab/16/16GHAE.png';
import build16GHsolarAE from 'assets/images/prefab/16/16GHsolarAE.png';
import build16solarAE from 'assets/images/prefab/16/16solarAE.png';

import { features } from './features';
import { getCalculatedValue } from 'helpers/orderHelpers';
import SuccessNotice from 'components/notices/SuccessNotice';

const buildImageLookup = {
  "6": build6,
  "6GH": build6GH,
  "6GHsolar": build6GHsolar,
  "6solar": build6solar,
  "12": build12,
  "12GH": build12GH,
  "12GHsolar": build12GHsolar,
  "12solar": build12solar,
  "15": build16,
  "15GH": build16GH,
  "15GHsolar": build16GHsolar,
  "15solar": build16solar,
  "15AE": build16AE,
  "15GHAE": build16GHAE,
  "15GHsolarAE": build16GHsolarAE,
  "15solarAE": build16solarAE,
}

const CREATE_RESERVATION = gql`
  mutation CreateReservation($firstName: String!, $email: String!, $lastName: String, $phoneNumber: String!, $addressOne: String!, $build: JSONObject) {
    createReservation(firstName: $firstName, email: $email, lastName: $lastName, phoneNumber: $phoneNumber, addressOne: $addressOne, build: $build) {
      id,
      email,
      lastName,
      firstName,
      phoneNumber,

    }
  }
`;

const sizes = [
  {size: 6, cost: '40,000', meta: {feeds: 95, production: 114, produce: 114, population: 380, lettuce: 2000}},
  {size: 12, cost: '70,000', meta: {feeds: 190, production: 228, produce: 228, population: 760, lettuce: 2400}},
  {size: 15, cost: '80,000', meta: {feeds: 250, production: 300, produce: "275-350", population: 1000, lettuce: 3150}},
]

const sizeLookup = {
  6: sizes[0],
  12: sizes[1],
  15: sizes[2],

}

const OrderPage = () => {
  const {setPage} = useContext(AppContext);
  const [build, setBuild] = useState({});
  const [customize, setCusomize] = useState(true);
  const [userInfo, setUserInfo] = useState({});
  const [showPayment, setShowPayment] = useState(false);
  const [createReservationMutation, { loading, data }] = useMutation(CREATE_RESERVATION);
  const [showSuccess, setShowSuccess] = useState();

  useEffect(() => setPage("Order"), [setPage]);

  const createReservation = () => {
    createReservationMutation({variables: {
      email: userInfo.email,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      phoneNumber: userInfo.phoneNumber,
      addressOne: userInfo.addressOne,
      build: build
    }})
    .then(res => {
      setShowPayment(true);
    })
  }
  const valid = () => {
    return build && userInfo.email && userInfo.phoneNumber && userInfo.addressOne && userInfo.firstName
  }

  const filterBuildFromChanges = (build, newBuild) => {
    for (let key in build) {
      if (build[key] && build[key].showCalculation && !getCalculatedValue(newBuild,build[key].showCalculation)) {
        delete newBuild[key];
      }
    }
    return newBuild
  }

  const removeBuildItem = (id) => {
    let newBuild = {...build, [id]: null};
    newBuild = filterBuildFromChanges(build, newBuild);
    setBuild({...newBuild});
  }

  const updateSize = (size, placeholder) => {
    let newBuild = {...build, size, default: placeholder};
    newBuild = filterBuildFromChanges({...newBuild}, newBuild);
    setBuild({...newBuild, size});
  }

  const containerImage = useMemo(() =>{
    let lookup = `${build.size}`
    if (build.greenhouse) {
      lookup += "GH";
    }
    if (build.solar) {
      lookup += "solar";
    }
    if (build.arcticEntrance) {
      lookup += "AE";
    }
    return buildImageLookup[lookup];
  }, [build]);

  const totalCost = useMemo(() =>{
    let total = build.size ? Number(sizeLookup[build.size].cost.replace(",","")) : 0;
    for (let key in build) {
      if (build[key] && key !== "size" && key !== 'default') {
        console.log("test",key)
        total += Number(getCalculatedValue(build, build[key].cost).toString().replace(",", ""));
      }
    }
    return total;
  }, [build]);

  const production = useMemo(() => {
    let total = 0;
    for (let key in build) {
      if (build[key] && key !== "size" &&  key !== 'default') {
        total += getCalculatedValue(build, build[key].meta.production);
      }
    }
    return Math.max(total, 0);
  }, [build]);

  const crops  = useMemo(() => {
    let plants = [];
    for (let key in build) {
      if (build[key] && key !== "size" && key !== 'default') {
        plants = [...plants, ...build[key].meta.crops];
      }
    }
    if (plants.length < 1) {
      return null;
    }
    plants.push("and more");
    return plants;
  }, [build]);

  const power  = useMemo(() => {
    let total = 0;
    for (let key in build) {
      if (build[key] && key !== "size" &&  key !== 'default') {
        total += getCalculatedValue(build, build[key].meta.power);
      }
    }
    return total;
  }, [build]);

  const getFeatures = () => {
    let features = [];
    for (let key in build) {
      if (build[key] && key !== "size" &&  key !== 'default') {
        if (build[key].meta.additional) {
          features = [...features, ...build[key].meta.additional];
        }
      }
    }
    return features;
  }

  return (
    <>
      {showPayment && data && <Modal naked close={() => setShowPayment(false)}>
        <PaymentForm onSuccess={() => setShowSuccess(true)}reservation={data.createReservation} productId={process.env.REACT_APP_STRIPE_RESERVATION_PRICE_ID}/>
      </Modal>}
      {showSuccess && 
        <SuccessNotice
          title="Success!"
          message="Your reservation for a Coldacre System has been made. We will be in contact with you soon."
          onClose={() => setShowSuccess(false)}
        />
      }
      <div className="flex flex-col">
        <div className="bg-cover pt-12">
          <PaddedPage className="flex gap-16 sm:flex-col" >
            <div className="w-1/2 sm:w-full flex flex-col">
                <h1 className="relative">
                  <span className="text-primary">Hydroponic</span>&nbsp;
                  <span className={`gradient ${(build.size == 6 && !build.default )? 'inline' : 'hidden'}`}>6</span>
                  <span className={`gradient ${(build.size == 12) ? 'inline' : 'hidden'}`}>12</span>
                  <span className={`gradient ${(build.size == 15) ? 'inline' : 'hidden'}`}>15</span>  
                </h1>
                <p>ColdAcre Hydroponic Container Farm</p>
                <div className="flex flex-col gap-24 sm:gap-16 mt-4">
                  <div className="p-6 rounded-lg bg-light-grey">
                    <p>
                      Select from the options below to, build out and reserve your farm.
                    </p>
                    <br></br>
                    <div>
                      <div className="flex cursor-pointer flex-col">
                        <small>Not sure where to start?</small>
                        <div>
                          <input checked={!customize} onChange={(e) => setCusomize(!customize)} type="checkbox" id="help" name="fav_language" value="CSS"/>
                          <label className="text-base cursor-pointer" for="help"> Let Our Team Help</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    customize &&
                    <>
                      <SizeSelector sizes={sizes} updateSize={updateSize}/>
                      {features.map(feature => (
                        ((!feature.showCalculation || getCalculatedValue(build, feature.showCalculation)) ?
                          <AddFeature 
                            key={feature.id}
                            addFeature={(feat) => setBuild({...build, [feature.id]: feat})} 
                            removeFeature={() => removeBuildItem(feature.id)} 
                            feature={feature}
                            build={build}
                            selected={build[feature.id]}
                          />
                          :
                          ''
                        )
                      ))}

                      <div className="w-full hidden sm:block flex flex-col self-start -mt-20 pb-4">
                        <div className="w-full h-80 relative ">
                          <img src={containerImage} className="absolute bottom-0" alt="ColdAcre container farm"/>
                          <h1 className="absolute bottom-4">
                            <span className={`gradient ${(build.size == 6 )? 'inline' : 'hidden'}`}>6</span>
                            <span className={`gradient ${(build.size == 12) ? 'inline' : 'hidden'}`}>12</span>
                            <span className={`gradient ${(build.size == 15) ? 'inline' : 'hidden'}`}>15</span>  
                          </h1>
                        </div>
                        <div className="flex flex-col items-end bg-light-grey text-sm border p-4 rounded border-grey scroller overflow-y-scroll">
                          <div className="w-full flex flex-col mb-2">
                            <strong className="w-full border-b border-dark">Features</strong>
                            {production ? <p className="">Produces {production}<sub>lbs/week</sub></p> : ''}
                            {crops ? <p className="">Grow {crops.join(", ")}<sub></sub></p> : ''}
                            {power ? <p className="">Uses {power}<sub>kWh/month</sub> of power</p> : ''}
                            {getFeatures().map((feature, index) => <p key={index} className="">{feature}</p> )}
                          </div>
                          {/* <strong className="w-full border-b border-dark">Cost</strong>
                          <div className="flex justify-between w-full gap-2">Containter Farm Base: <span>${sizeLookup[build.size] && sizeLookup[build.size].cost}</span></div>
                          {Object.entries(build).filter((current) => current[0] !== "size" && current[1]).map(([key]) =>
                            <div className="flex justify-between w-full gap-2" key={key}>{key !== "size" && build[key] &&  build[key].name}: <span>${getCalculatedValue(build, build[key].cost)}</span></div>
                          )}
                          <hr className="w-full border-white"></hr>
                          <div className="flex justify-between w-full gap-2">
                            <strong>Total:</strong> <span className="font-bold">${totalCost}</span>
                          </div> */}
                        </div>
                      </div>
                    </>
                  }
                  <div className="flex flex-col gap-4">
                    <label className="">Info</label>
                    <p>
                      With some information our team will be in contact with you to process your reservation!                    
                    </p>
                    <div className="flex gap-2">
                      <div className="flex grow flex-col">
                        <label>First Name</label>
                        <Input onChange={(firstName) => setUserInfo({...userInfo, firstName})}/>
                      </div>
                      <div className="flex grow flex-col">
                        <label>Last Name</label>
                        <Input onChange={(lastName) => setUserInfo({...userInfo, lastName})}/>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <label>Email</label>
                      <Input onChange={(email) => setUserInfo({...userInfo, email})}/>
                    </div>
                    <div className="flex flex-col">
                      <label>Phone Number</label>
                      <Input onChange={(phoneNumber) => setUserInfo({...userInfo, phoneNumber})}/>
                    </div>
                    <div className="flex flex-col">
                      <label>Address</label>
                      <Input onChange={(addressOne) => setUserInfo({...userInfo, addressOne})}/>
                    </div>
                  </div>

                  <div className="flex flex-col gap-4">
                    {/* {
                      customize &&
                      <>
                        <ul>
                          <li>Quote: ${totalCost}*</li>
                        </ul>
                        <small className="text-center">
                          * Prices shown exclude transport and may vary by territory/province.
                        </small>
                      </>
                    } */}
                    <ButtonPrimary disabled={loading || !valid()} onClick={createReservation} className="mt-2" >Reserve</ButtonPrimary>
                  </div>
                </div>
            </div>
            <div className="w-1/2 sm:hidden sticky top-1 flex flex-col self-start h-screen -mt-20 pb-4">
              <div className="w-full h-80 relative ">
                <img src={containerImage} className="absolute bottom-0" alt="ColdAcre container farm"/>
                <h1 className="absolute bottom-4">
                  <span className={`gradient ${(build.size == 6 )? 'inline' : 'hidden'}`}>6</span>
                  <span className={`gradient ${(build.size == 12) ? 'inline' : 'hidden'}`}>12</span>
                  <span className={`gradient ${(build.size == 15) ? 'inline' : 'hidden'}`}>15</span>  
                </h1>
              </div>
              <div className="flex flex-col items-end bg-light-grey text-sm border p-4 rounded border-grey scroller overflow-y-scroll">
                <div className="w-full flex flex-col mb-2">
                  <strong className="w-full border-b border-dark">Features</strong>
                  {production ? <p className="">Produces {production}<sub>lbs/week</sub></p> : ''}
                  {crops ? <p className="">Grow {crops.join(", ")}<sub></sub></p> : ''}
                  {power ? <p className="">Uses {power}<sub>kWh/month</sub> of power</p> : ''}
                  {getFeatures().map((feature, index) => <p key={index} className="">{feature}</p> )}
                </div>
                {/* <strong className="w-full border-b border-dark">Cost</strong>
                <div className="flex justify-between w-full gap-2">Containter Farm Base <span>${sizeLookup[build.size] && sizeLookup[build.size].cost}</span></div>
                <div className="flex justify-between w-full gap-2">Warranty+ <span>included</span></div>
                {Object.entries(build).filter((current) => current[0] !== "size" && current[1]).map(([key]) =>
                  <div className="flex justify-between w-full gap-2" key={key}>{key !== "size" && build[key] &&  build[key].name} <span>${getCalculatedValue(build, build[key].cost)}</span></div>
                )}
                <hr className="w-full border-white"></hr>
                <div className="flex justify-between flex-col w-full gap-2">
                  <div className="flex justify-between w-full gap-2">
                    <strong>Total:</strong> <span className="font-bold">Starting at ${totalCost} + GST</span>
                  </div>
                  <small>
                    *Amount listed is an estimate based on selected components. Your system will be customized with the guiance of a ColdAcre Systems Consultant to ensure optimal capacity and return on investment. 
                    Shipping costs not included.
                  </small>
                </div> */}
              </div>
            </div>
          </PaddedPage>
        </div>
      </div>
    </>
  );
};

export default OrderPage;
