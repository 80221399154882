import React from 'react'
import { PaddedPage } from 'components/common/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf, faLocationDot, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import card1 from 'assets/images/landing/card1.jpg';
import card2 from 'assets/images/landing/card2.jpg';
import card3 from 'assets/images/landing/card3.jpg';
import leaves from 'assets/images/landing/leaves.png';
import ButtonPrimary from 'components/common/Button';
import ButtonPrimaryOutline from 'components/common/Button/ButtonPrimary';
import { Link } from 'react-router-dom';


export default function Cards() {
  return (
    <div className="flex flex-col">
      <div className="bg-black">
        <PaddedPage className="flex flex-col items-stretch gap-8 py-2">
          <div className="flex flex-col justify-center gap-3">
            <h3 className="text-2xl text-white font-med text-center">Partner Announcement on ColdAcre Food Systems</h3>
            <p className="text-med-grey">
              A core value that is shared between the Na-Cho Nyäk Dun Development Corporation, Solvest Inc., and ColdAcre Food Systems is the spirit of innovation. The courage to take risks and think outside of the box is a sentiment that is shared by our partnership, and has been the catalyst to our work. Engaging in innovative pursuits with a mission to create food sovereignty solutions for Indigenous communities across Canada is an endeavor that we are proud of and stand by.
            </p>
            <p className="text-med-grey">
              However, with innovative work not every initiative is a success. Development is a journey of trial and error, and in the times in which the other side of innovation arrives, we are called to face it with the courage and conviction as our successes. It is in this sentiment, with pride and sadness in equal measure, that we share that NNDDC, Solvest Inc. and Tarek Bos-Jabbar have made the decision to close Cold Acre Food Systems. 
            </p>
            <p className="text-med-grey">
              All partners remain dedicated to ColdAcre’s central mission of uplifting and empowering communities to take hold of their food security and sovereignty. Within this work we shared many successes, and many more learnings. Learnings that will no doubt continue to inform and inspire our work across many areas.
            </p>
            <p className="text-med-grey">
              During the course of ColdAcre’s acquisition into NNDDC’s subsidiaries the economic landscape of the Yukon significantly changed. Many people were affected by the failure at Victoria Gold’s Eagle Gold Mine, including NNDDC who needed to ensure they move forward with resiliency in mind. As a result, difficult decisions had to be made.
            </p>
            <p className="text-med-grey">
              As we move through this decision, our partnerships remain strong. While we are choosing to end this initiative, we are confident that opportunities to collaborate in the future will arise. 
            </p>
            <h4 className="text-white mt-6">
              What Does This Mean For Our Customers? 
            </h4>
            <p className="text-med-grey">
              For our customers, we are working to deliver the final units to our clients and ensuring that they are empowered and prepared to take on the operation of these units to support their unique food ecosystem initiatives. We are proud to be able to fulfill all our final orders, and end ColdAcre in a good way. 
            </p>
            <p className="text-med-grey">
              Earlier this season we ended our Winter Harvest subscription. We are grateful to have been a joyful part of so many Yukoner’s meals. We hope we have inspired local food and year-round growing in our community.
            </p>
            <p className="text-med-grey">
              Our last day of food distribution will be on October 18th, 2024. After that day our farm will be permanently shut down and unable to fulfill orders.
            </p>
            <p className="text-med-grey">
              We wish to express our gratitude to all of our business partners, our suppliers, and of course our customers for joining us on this journey of innovation in rethinking how food is made available and in control of the people in our home and across rural Canada. 
            </p>
            <p className="text-white">
              Thank you
            </p>
            {/* <a href="https://www.nnddc.ca/post/investing-in-self-determination-and-food-sovereignty-na-cho-ny%C3%A4k-dun-development-corporation-acquir"><ButtonPrimary className=" p-12 px-10 font-bold mt-4 border-white text-white hover:bg-med-grey">Find Out More</ButtonPrimary></a> */}
          </div>
        </PaddedPage>
      </div>
      <PaddedPage className="flex flex-col items-stretch gap-8">
        <div className="flex flex-col justify-center text-center pb-12">
          <h1 className="jumbo">ColdAcre seeks a solution that works for your community.</h1>
          <h3 className="text-med-grey text-2xl font-med">Lets find it together.</h3>
          <Link to="/about"><ButtonPrimary className=" p-12 px-10 font-bold mt-4 bg-light-grey hover:bg-med-grey">Get Started by Exploring Our Systems</ButtonPrimary></Link>
          {/* <h2 className="mt-2">
              Commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </h2> */}
        </div>
      </PaddedPage>
      <div className="h-32 -mt-40 border-b-2 border-black opacity-80 sm:hidden" >
        <img src={leaves} className="invert h-32 m-auto" alt="ColdAcre container farm"/>
      </div>
      <PaddedPage className="flex flex-col items-stretch gap-8">
        <div className="flex justify-between flex-col mt-12 gap-4 sm:mt-0 sm:gap-16">
          <div className="flex gap-12 sm:flex-col items-center">
            <div className="rounded-xl bg-primary h-96 w-80 shrink-0 bg-cover bg-center flex justify-center items-center"
              style={{ backgroundImage: `url(${card2})` }}
            >
              <FontAwesomeIcon className={`text-white text-9xl`} icon={faLightbulb} />
            </div>
            <div>
              <h2>We're invested in your success.</h2>
              <h3 className="text-med-grey text-lg font-med">That's why we pride ourselves in being able to guarantee yields for our customers. Through full on-site training at our commercial farm, continued support, and our NEW 3-Year Warranty, we've made it our responsibility to support  each community we enter with net positive operations.</h3>
              <Link to="/coldacreplus" className="self-center"><ButtonPrimary small="true" className="p-12 px-10 font-bold mt-4 hover:bg-med-grey">Find Out More</ButtonPrimary></Link>
            </div>
          </div>
          <div className="flex gap-12 sm:flex-col-reverse  items-center justify-end self-end  bg-light-grey sm:bg-white w-full">
            <div className="flex flex-col items-end sm:items-start">
              <h2>WinterHarvest Food Subscription </h2>
              <h3 className="text-med-grey text-lg font-med text-right sm:text-left">Explore our local Yukon food subscription box full of local<br></br> products and ColdAcre-grown ingredients.</h3>
              <a href="https://winterharvest.coldacre.ca" className=""><ButtonPrimary small="true" className="p-12 px-10 font-bold mt-4 hover:bg-med-grey">Learn More</ButtonPrimary></a>
            </div>
            <div className="rounded-xl bg-primary h-96 w-80 shrink-0 flex sm:mt-0 justify-center bg-cover items-center"
              style={{ backgroundImage: `url(${card3})` }}
            >
              <FontAwesomeIcon className={`text-white text-9xl`} icon={faLeaf} />
            </div>
          </div>
          <div className="flex gap-12 sm:flex-col items-center">
            <div className="rounded-xl bg-primary h-96 w-80 shrink-0 bg-cover bg-center flex justify-center items-center"
              style={{ backgroundImage: `url(${card1})` }}
            >
              <FontAwesomeIcon className={`text-white text-9xl`} icon={faLocationDot} />
            </div>
            <div>
              <h2>We grow it all, and so can you.</h2>
              <h3 className="text-med-grey text-lg font-med">While some of our competitor systems are limited to growing leafy greens, ColdAcre mixes old and new technologies to grow all kinds of vegetables including fruiting crops - separating us from other hydroponic producers.</h3>
              <Link to="/about" className="self-center"><ButtonPrimary small="true" className="p-12 px-10 font-bold mt-4 hover:bg-med-grey">Start Building your System Today</ButtonPrimary></Link>
            </div>
          </div>
        </div>
      </PaddedPage>
    </div>
  )
}
