import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';

export default function FAQuestion({question, startOpen}) {
    const [open, setOpen] = useState(startOpen);
    const options = {
        renderNode: {
            [INLINES.HYPERLINK]: (node, children) => (
            <button className="btn btn-sm btn-outline-primary">{children}</button>
            ),
        },
    };
    return (
        <div className="flex-column mb-4">
            <div style={{cursor: "pointer"}} className="d-flex justify-content-between" onClick={() => setOpen(!open)}>
                <h3 className="w-75">{question.question}</h3>
                {open ?
                    <FontAwesomeIcon className="text-primary" icon={faMinus} size='lg'></FontAwesomeIcon>
                    :
                    <FontAwesomeIcon className="text-primary" icon={faPlus} size='lg'></FontAwesomeIcon>
                }
            </div>
            {open && <div className="w-75">
                {documentToReactComponents(question.answer.json, options)}
            </div>}
        </div>
    )
}
