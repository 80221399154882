const getCalculatedValue = (order, calculation) => {
    try {
      return toValue(eval(`(${calculation})`));
    } catch (e) {
      console.log("calculation error", calculation, e, order);
      return undefined;
    }
  }

  const toValue = (val) => {
    if (Number(val)) {
      return Number(val);
    } else if (val == 'true') {
      return true;
    } else if (val == 'false') {
      return false;
    } else {
      return val;
    }
  }

  export {
      getCalculatedValue
  }