import React, {useContext, useEffect} from 'react';
import { PaddedPage } from 'components/common/Layout';
import AppContext from 'contexts/AppContext';
import ButtonPrimary from 'components/common/Button';
import { Link } from 'react-router-dom';
import refurb from 'assets/images/plus/refurb.jpg';
import advise from 'assets/images/plus/advise.jpg';
import consult from 'assets/images/plus/consult.jpg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf, faPhone } from '@fortawesome/free-solid-svg-icons';
import contact from 'assets/images/contact/contact.jpg';
import bottom from 'assets/images/contact/bottom.jpg';

const PlusPage = () => {
  const {setPage} = useContext(AppContext);
  useEffect(() => setPage("about"), [setPage]);

  return (
    <div className="flex flex-col">
      <div className="bg-cover bg-bottom bg-no-repeat" style={{backgroundImage: `url(${contact})`}}>
        <div className="backdrop-brightness-75">
          <PaddedPage className="flex h-20vh" >
            <div className="w-2/3 flex flex-col justify-center items-start gap-8">
                <h1 className="jumbo text-white backdrop-brightness-90">
                  Coldacre+
                </h1>
            </div>
          </PaddedPage>
        </div>
      </div>
      <PaddedPage>
        <div className="flex justify-between flex-col sm:mt-0">
          <div className="flex gap-12 items-center">
            <div className="rounded-xl mb-4 bg-primary h-60 w-80 shrink-0 bg-cover bg-center flex justify-center items-center"
              style={{backgroundImage: `url(${refurb})`}}
            >
            </div>
            <div>
              <h2>Refurb & Update</h2>
              <h3 className="text-med-grey text-lg font-med">
                Use ColdAcre+ Refurb and Update services to evolve existing ColdAcre systems or update and evolve existing hydroponic systems. 
              </h3>
            </div>
          </div>
          <div className="flex gap-12 items-center">
            <div className="rounded-xl mb-4 bg-primary h-60 w-80 shrink-0 bg-cover bg-center flex justify-center items-center"
              style={{backgroundImage: `url(${consult})`}}
            >
            </div>
            <div>
              <h2>Site Training </h2>
              <h3 className="text-med-grey text-lg font-med">
                It's our great pleasure to offer 2-week hands-on training sessions at ColdAcre's commerical facility for outside organizations. 
                Learn how to operate a hydroponic system from seed to harvest. 
              </h3>
            </div>
          </div>
          <div className="flex gap-12 items-center">
            <div className="rounded-xl mb-4 bg-primary h-60 w-80 shrink-0 bg-cover bg-center flex justify-center items-center"
              style={{backgroundImage: `url(${advise})`}}
            >
            </div>
            <div>
              <h2>System & Commercial Program Consulting</h2>
              <h3 className="text-med-grey text-lg font-med">
                Available services include:
                <ul>
                  <li>
                    Shipping container retrofits and sourcing
                  </li>
                  <li>
                    Structural and electrical engineering 
                  </li>
                  <li>
                    Permitting guidance and consulting
                  </li>
                  <li>
                    In-house spray foam services
                  </li>
                  <li>
                    In-house CAD design services
                  </li>
                  <li>
                    Electrical and HVAC
                  </li>
                  <li>
                    Sanitary food production design (for commercial food production)
                  </li>
                </ul>
              </h3>
            </div>
          </div>
        </div>
      </PaddedPage>
      <div className="bg-cover bg-center bg-no-repeat" style={{backgroundImage: `url(${bottom})`}}>
        <div className="backdrop-brightness-50 flex items-center justify-center">
          <PaddedPage className="flex justify-center w-full">
            <div className="flex flex-col items-center text-center">
              <h1 className="text-white">Let's Talk! <FontAwesomeIcon icon={faPhone} /></h1>
              <Link to="/contact">
                <ButtonPrimary className="mt-2 w-full" white >Contact us Today</ButtonPrimary>
              </Link>
            </div>
          </PaddedPage>
        </div>
      </div>
    </div>
  );
};

export default PlusPage;
