import React from 'react';

export default function Transparent({children, less}) {
  return (
    <div className={`fixed top-0 w-full h-full ${less ? 'z-10' : 'z-20'}`}>
          <div 
            className="absolute top-0 bottom-0 left-0 right-0"
            style={{
              backdropFilter: "blur(6px)"
            }}
          />
            {children}
    </div>
  )
}
