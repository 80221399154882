import React from 'react';

const PaddedPage = ({ children, className, noTop, noBottom }) => {
  return (
    <div className={`
      sm:px-6
      md:px-12 
      lg:px-16 
      xl:px-24
      m-auto
      ${
        !noTop &&
        `sm:pt-6
        md:pt-12 
        lg:pt-16 
        xl:pt-24 `
      }
      ${!noBottom && `
        sm:pb-6
        md:pb-12 
        lg:pb-16 
        xl:pb-24 
        `}
      2xl:max-w-7xl 
      2xl:p-auto
      ${className}
      `
    }
    style={{
      paddingTop: ``
    }}
    >
      {children}
    </div>
  );
};

export default PaddedPage;

