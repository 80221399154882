import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import containerModel from 'assets/models/container18.obj'

const manager = new THREE.LoadingManager();
const loader = new OBJLoader(manager);

export default function ModelDiagram({parentRef}) {
  const canvasRef = useRef();
  const [scene, setScene] = useState();
  const [renderer, setRenderer] = useState();
  const [camera, setCamera] = useState();
  const requestRef = useRef()
  const [rotate, setRotate] = useState(false);
  const [model, setModel] = useState();

  useEffect(()=> {
    let ww = 600;
    let wh = 600;
    const renderer = new THREE.WebGLRenderer({canvas : canvasRef.current });
    setRenderer(renderer);
    renderer.setSize(ww,wh);
    renderer.gammaInput = true;
    renderer.gammaOutput = true;
    const scene = new THREE.Scene();
    scene.background = new THREE.Color('#0E0E0E');
    const camera = new THREE.PerspectiveCamera(50,ww/wh, 0.1, 10000 );
    setCamera(camera);
    camera.position.set(0,0,600);
    camera.lookAt(new THREE.Vector3(0,0,0));
    scene.add(camera);
  
    //Add a light in the scene
    const directionalLight = new THREE.DirectionalLight( 0xFFFBE5, 0.8 );
    directionalLight.position.set( -250, 800, 350 );
    scene.add( directionalLight);
    const directionalLight2 = new THREE.DirectionalLight( 0xF48225, 0.5 );
    directionalLight2.position.set( 250, 800, 250 );
    directionalLight2.lookAt(new THREE.Vector3(0,0,0));
    scene.add( directionalLight2);
    const light = new THREE.AmbientLight( 0x404040, 0.4 ); // soft white light
    scene.add( light );
    loader.load(containerModel, (model) => {
      model.rotation.z = Math.PI/180 * -50;
      model.rotation.x = Math.PI/180 * -75;
      model.rotation.y = Math.PI/180 *  0;
      model.position.x = -250;
      model.position.y = 0;
      model.position.z = 0;
      //Go through all children of the loaded object and search for a Mesh
      model.traverse( function ( child ) {
        //This allow us to check if the children is an instance of the Mesh constructor
        if(child instanceof THREE.Mesh){
          // child.material.color = new THREE.Color(0X00FF00);
          //Sometimes there are some vertex normals missing in the .obj files, ThreeJs will compute them
          child.geometry.computeVertexNormals();
        }
      });
      //Add the 3D object in the scene
      scene.add(model);
      setModel(model);
      renderer.render(scene, camera);
    });
    setScene(scene);
    return () => {
      renderer.clear();
    }
  }, []);
  
  const animate = () => {
    // The 'state' will always be the initial value here
    model.rotation.z += .01;
    renderer.render(scene, camera);
    requestRef.current = requestAnimationFrame(animate);
  }
    
  useEffect(() => {
    if (rotate) {
      requestRef.current = requestAnimationFrame(animate);
    } else {
      cancelAnimationFrame(requestRef.current);
    }
    return () => {
      cancelAnimationFrame(requestRef.current);
    }
  }, [rotate]); // Make sure the effect runs only once

  const windowResize = useCallback(() => {
    if (canvasRef.current && renderer) {
        let width = parentRef.current.clientWidth;
        let height = parentRef.current.clientWidth;
        canvasRef.current.width  = parentRef.current.clientWidth * 2;
        canvasRef.current.height = parentRef.current.clientWidth * 2;
        renderer.setSize(width, height);
        // drawCanvas();
    }
  }, [renderer]);

  useEffect(() => {
    window.addEventListener('resize', windowResize);
    windowResize();
  }, [windowResize]);

  return (
    <canvas ref={canvasRef} id="myCanvas" onMouseEnter={() => setRotate(true)} onMouseLeave={() =>setRotate(false)} className="" width="1200" height="1200"></canvas>
  )
}
