import React, {useContext, useEffect} from 'react';
import { PaddedPage } from 'components/common/Layout';
import PlantBG from 'assets/images/landing/plant.jpg'
import PlantBottom from 'assets/images/landing/bottom.jpg'
import AppContext from 'contexts/AppContext';
import CrossSectionJumbo from './CrossSectionJumbo';
import hydroponicPlantTop from 'assets/images/landing/hydro-plant-top.png';
import funkyLines from 'assets/images/landing/funky-lines.png'
import Preview from './Preview';
import Cards from './Cards';
import Banner from './Banner';
import about from 'assets/images/landing/about.jpg';
import ourStory from 'assets/images/landing/ourstory.jpg';
import oval from 'assets/images/landing/oval.jpg';
import logo from 'assets/images/logo/LogoFullColor.png'
import ButtonPrimary from 'components/common/Button';
import { Link } from 'react-router-dom';


const LandingPage = () => {
  const {setPage} = useContext(AppContext);
  useEffect(() => setPage(), [setPage]);

  return (
    <div className="flex flex-col">
      <Banner/>
      <Cards/>
      <div className="">
        <PaddedPage>
          <div className="rounded-2xl h-oval w-full bg-cover bg-center flex flex-col justify-center items-center" style={{backgroundImage: `url(${about})`}}>
            <h2 className="mt-12 text-center p-4 backdrop-brightness-75 height-full">
              <span className="text-white backdrop-blur rounded-xl inline-block">
              ColdAcre exists to make a difference in Indigenous and Northern communities by providing access and the means to reliable, community-grown fresh produce.
              </span>
              <br></br> 
              <br></br>
              <Link to="/about"><ButtonPrimary className="relative bottom-2 p-12 px-10 font-bold border-white bg-white">Learn More about ColdAcre</ButtonPrimary></Link>
            </h2>
            
          </div>
        </PaddedPage>
      </div>
      {/* <PaddedPage className="flex relative z-10 gap-16 sm:flex-col">
        <div className="bg-cover bg-no-repeat min-h-80 rounded-2xl bg-bottom -mb-2 w-1/3 sm:w-full" style={{backgroundImage: `url(${ourStory})`}} />
        <div className="w-1/2 sm:w-full">
          <h1 className="jumbo ">Who is ColdAcre?</h1>
          <h3 className="text-med-grey text-2xl font-med w-80">Our story</h3>
          <p className="mt-4 ">
            ColdAcre is a Yukon grown company that started with a goal to increase food resiliancy in northern Canada, by creating systems to grow food independent of weather and location in a efficinet and sustainable way. <br></br>
            ColdAcre now offers these systems for puchase in a variety of configurations to meet a multiplicy of community, business and individual needs.<br></br>
            <br></br>
            As a byproduct of testing and developing these farms, ColdAcre produces a surplus of fresh greens that it offers to the commity via a subscription service, <span className="lightgradient font-bold">WinterHarvest</span>.
          </p>
        </div>

      </PaddedPage> */}
      {/* <CrossSectionJumbo/> */}
    </div>
  );
};

export default LandingPage;
