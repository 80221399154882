import React from 'react';
import RequestFlagsContext from 'contexts/RequestFlagsContext';
import useErrors from 'hooks/useErrors';

const RequestFlagsProvider = ({ children }) => {
  const {errors, currentError, addError, unauthorized, removeError} = useErrors();
  return (
    <RequestFlagsContext.Provider value={{ 
        errors,
        currentError,
        addError,
        unauthorized,
        removeError
    }} >
    {children}
    </RequestFlagsContext.Provider>
  );
};

export default RequestFlagsProvider;
