import React, { useState, useEffect } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Input from 'components/inputs';
import PaymentMethod from './PaymentMethod';
import ButtonPrimary from 'components/common/Button';
import { useNavigate } from 'react-router-dom';
import { Checkbox } from 'components/inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import Loading from 'components/common/Loading';
import Message from 'components/common/Message';

const appearance = {
  style: {
    base: {
      margin: '5px 15px',
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '1em',
      '::placeholder': {
        color: 'rgba(0,0,0,.4)'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};


function StripeCardSetupForm({ paymentIntent, paymentMethods, setPaymentMethods, handleSuccess, returnUrl }) {
  const [paymentInfo, setPaymentInfo] = useState(false);
  const [showNewPayment, setShowNewPayment] = useState(paymentMethods ? false : true);
  const [savedPaymentMethod, setSavedPaymentMethod] = useState(paymentMethods ? paymentMethods[0].id : null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [cost, setCost] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  useEffect(() => {
    if (paymentMethods) {
      setSavedPaymentMethod(paymentMethods[0].id);
      setShowNewPayment(false);
    }
  }, [paymentMethods]);

  const validateForm = () => {
    return !loading && !message.success && (showNewPayment || savedPaymentMethod);
  };


  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements || !paymentIntent) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setMessage({ success: false, content: 'Payment could not be processed.' });
      return;
    }

    if (!showNewPayment && savedPaymentMethod) {
      setLoading(false);
      return handleSuccess(savedPaymentMethod);
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
      // Uncomment below if you only want redirect for redirect-based payments
      redirect: 'if_required',
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      setMessage({ success: false, content: result.error.message });
      setLoading(false);
      console.log(result.error.message);
    } else {
      // The payment has been processed!
      setMessage({
        success: true, 
        content: 'Success, payment method recieved.'
      });
      setLoading(false);
      handleSuccess();
    }
  };

  const handleRemovePaymentMethod = (method) => {
    // TODO remove a payment using method.id
    // then setShow
    // removePaymentMethod(method.id)
    //   .then(() => {
    //     if (savedPaymentMethod === method.id) {
    //       setSavedPaymentMethod(null);
    //       setPaymentMethods(current => {
    //         let filtered = current.filter(currentMethod => currentMethod.id !== method.id);
    //         if (filtered.length < 1) {
    //           setShowNewPayment(true);
    //           return null;
    //         }
    //         return filtered;
    //       }
    //       );
    //     }
    //   });
  };

  return (
    <>
      <div className="flex flex-col gap-4">
        <h3>Reserve Now!</h3>
        <div className="flex flex-col gap-2 p-2 bg-light-light-grey rounded">
          <div><small>Reservation Fee for ColdAcre container farm</small></div>
          <div><span className="font-medium">Total: ${paymentIntent.amount/100.0}</span>{cost}</div>
        </div>
        <div className="flex justify-between items-center">
          <strong>Card</strong>
          { paymentMethods && <ButtonPrimary size="mini" onClick={() => setShowNewPayment(!showNewPayment)}>{ showNewPayment ? 'Saved Card' : 'New Card' }</ButtonPrimary> }
        </div>
        {
          paymentMethods && !showNewPayment ?
            <div className="flex flex-column gap-2 mb-3">
              {paymentMethods.map((method, index) => <PaymentMethod key={index} method={method} selected={savedPaymentMethod} setPaymentMethod={setSavedPaymentMethod} removePaymentMethod={handleRemovePaymentMethod}/>)}
            </div>
            :
            <>
              <div>
                <PaymentElement id="card-element" />
              </div>
            </>
        }
        {/* <Checkbox
          name="terms"
          checked={paymentInfo.terms}
          className="mr-2"
          onChange={() => {
            setPaymentInfo({ ...paymentInfo, terms: !paymentInfo.terms });
          }}
          text={
            <label>
              I have read and agree to the&nbsp; 
              <a href={'/terms'} rel="noreferrer" target="_blank">Terms and Conditions</a>,&nbsp; 
            </label>
          }
          required
        /> */}
        <ButtonPrimary
          margin
          primary basic
          disabled={!validateForm()}
          onClick={handleSubmit}
        >
          {loading || !paymentIntent ?
            <Loading/>
            : 'Reserve Now'
          }
        </ButtonPrimary>
      </div>
      {
        message.content &&
        <Message attached='bottom' negative={!message.success} positive={message.success}>
          {message.success ? 
            <FontAwesomeIcon className="text-xs text-green-400" icon={faCheck} />
            :
            <FontAwesomeIcon className="text-xs text-green-400" icon={faTimes} />
          }
          {message.content}
        </Message>
      }
    </>
  );
}

export default StripeCardSetupForm;

