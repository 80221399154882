import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import appContext from 'contexts/AppContext';
import { Link } from 'react-router-dom';
import UserNav from './UserNav';
import AnonNav from './AnonNav';
import SalesNav from './SalesNav';
import Logo from 'assets/images/logo/LogoFullColor.png';


const NavBar = ({ childRef }) => {
  const location = useLocation();
  const { currentUser, page } = useContext(appContext);
  const [top, setTop] = useState(true);
  const [toggleMenu, setToggleMenu] = useState(true);

  useEffect(() => {
    if (location.pathname === '/') {
      const handleScroll = () => {
        var scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
        setTop(scrollTop < 200);
      };
      window.addEventListener('scroll', handleScroll);
      return (() => window.removeEventListener('scroll', handleScroll));
    } else {
      setTop(true);
    }
  }, [location.pathname]);

  return (
    <div
    className={`
      ${!page && 
        'sticky'
      }
      top-0
      flex 
      justify-center
      bg-transparent-white
      border-b
      z-30
    `}
    style={{
      backdropFilter: "blur(1px)"
    }}>
      <div  
        ref={childRef} 
        className={`
          flex
          justify-between
          py-3
          w-full
          sm:px-6
          md:px-12 
          lg:px-16 
          xl:px-24 
          h-16
          2xl:max-w-7xl 
          2xl:p-auto
          2xl:max-w-7xl
        `}
      > 
        <Link to="/">
          <div className="w-32 h-10 bg-contain absolute" style={{backgroundImage: `url(${Logo})`}}/>
        </Link>
        <div className="flex h-full justify-center sm:justify-end items-center">
          <SalesNav/>
        </div>
        <div className="flex h-full justify-center sm:hidden items-center">
          <a className="no-underline" href="https://winterharvest.coldacre.ca/">WinterHarvest</a>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
